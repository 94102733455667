import React from "react";
import "./seo.css";

function searchengineoptimization() {
  return (
    <>
      <div
        className="container-fluid d-flex justify-content-center align-items-center header-section"
        style={{minHeight:'50vh'}}
      >
        <div className="text-center mt-5">
          <h1 className="mb-4">Search Engine Optimization</h1>
          <h5>Home | Search Engine Optimization</h5>
        </div>
      </div>

      <div className="container-fluid bg-white text-dark py-5 font-bootstrap">
        <div className="row justify-content-center">
          <div className="col-md-8 text-center">
            <p className="mb-5">
              For each query entered into its search engine, Google uses SEO to
              determine which websites should rank high. SEO is essential <br />
              because it maintains the impartiality of search results.
            </p>
            <p>
              HumanTek can design the most effective SEO strategy for your
              company. You’ve come to the correct place if you’ve decided to{" "}
              <br />
              move forward because we can assist you with all the aspects of
              building your internet presence and reaching out to your <br />{" "}
              potential customers.
            </p>
          </div>
        </div>
      </div>

      <section className="sectionsearch">
        <div className="containersearch">
          <div className="columnsearch text-column">
            <h5 className="heading5">Prioritize SEO Tactics</h5>
            <h2 className="heading">Keyword Strategy Planning</h2>
            <p className="text">
              HumanTek’s SEO professionals are knowledgeable about SEO tactics
              to raise your rankings for particular keywords. We identify the
              phrases with the optimal mix of relevance, search volume, and
              ranking potential.
            </p>
          </div>
          <div className="columnsearch image-column">
            <img
              className="image"
              src="https://thehumantek.com/wp-content/uploads/2023/08/Keyword-Strategy-Planning.png"
              alt="Keyword Strategy Planning"
              title="Keyword Strategy Planning"
            />
          </div>
        </div>
      </section>

      <section className="sectionsearch">
        <div className="containersearch">
          <div className="columnsearch image-column">
            <img
              className="image"
              src="https://thehumantek.com/wp-content/uploads/2023/08/Site-Audit-and-Optimization.png"
              alt="Keyword Strategy Planning"
              title="Keyword Strategy Planning"
            />
          </div>

          <div className="columnsearch text-column">
            <h5 className="heading5">Evaluate Speed and Images</h5>
            <h2 className="heading">Site Audit and Optimization</h2>
            <p className="text">
              HumanTek’s SEO experts offer thorough website audit & optimization
              services by addressing technical SEO elements, social media ad
              campaigns, CRO backlink profiles, and analytics. We make
              commercial decisions that help the development of your brand
              narrative.
            </p>
          </div>
        </div>
      </section>

      <section className="sectionsearch">
        <div className="containersearch">
          <div className="columnsearch text-column">
            <h5 className="heading5">Do better than the Market</h5>
            <h2 className="heading">Competitor Analysis</h2>
            <p className="text">
              Our SEO specialists thoroughly research your firm’s competitors to
              carefully watch your online tactics and position your website at
              the top of search results. For our clients, we work adeptly to
              generate exceptional and concrete results on search engines.
            </p>
          </div>
          <div className="columnsearch image-column">
            <img
              className="image"
              src="https://thehumantek.com/wp-content/uploads/2023/08/Competitor-Analysis.png"
              alt="Keyword Strategy Planning"
              title="Keyword Strategy Planning"
            />
          </div>
        </div>
      </section>

      <section className="sectionsearch">
        <div className="containersearch">
          <div className="columnsearch image-column">
            <img
              className="image"
              src="https://thehumantek.com/wp-content/uploads/2023/08/Technical-SEO.png"
              alt="Keyword Strategy Planning"
              title="Keyword Strategy Planning"
            />
          </div>

          <div className="columnsearch text-column">
            <h5 className="heading5">Make the Crawlers find you</h5>
            <h2 className="heading">Technical SEO</h2>
            <p className="text">
              Technical SEO services for WordPress and other CMS are essential
              for online success. At HumanTek, Data-driven technical SEO
              recommendations are identified, planned, and carried out to
              deliver the best results.
            </p>
          </div>
        </div>
      </section>

      <section className="sectionsearch">
        <div className="containersearch">
          <div className="columnsearch text-column">
            <h5 className="heading5">Earn Organic Traffic</h5>
            <h2 className="heading">On-Page SEO</h2>
            <p className="text">
              The main objective of on-page SEO is to interact with search
              engine crawlers in their language and make it easier for them to
              understand the actual context of your pages. Our on-page SEO
              professionals will examine your web pages and develop a strategy
              that meets your digital goals.
            </p>
          </div>
          <div className="columnsearch image-column">
            <img
              className="image"
              src="https://thehumantek.com/wp-content/uploads/2023/08/on-page-seo.png"
              alt="Keyword Strategy Planning"
              title="Keyword Strategy Planning"
            />
          </div>
        </div>
      </section>

      <section className="sectionsearch">
        <div className="containersearch">
          <div className="columnsearch image-column">
            <img
              className="image"
              src="https://thehumantek.com/wp-content/uploads/2023/08/Backlinks-Building-1.png"
              alt="Keyword Strategy Planning"
              title="Keyword Strategy Planning"
            />
          </div>

          <div className="columnsearch text-column">
            <h5 className="heading5">Get Strong Backlinks</h5>
            <h2 className="heading">Backlinks Building</h2>
            <p className="text">
              Backlink data sources are required to identify the referring
              domains, evaluate the website authority and calculate the social
              share volume of linking pages. Our experts do backlink building to
              ensure you get incoming links from reliable and high-authority
              websites
            </p>
          </div>
        </div>
      </section>

      <section className="sectionsearch">
        <div className="containersearch">
          <div className="columnsearch text-column">
            <h5 className="heading5">
              Improve the performance of your Business
            </h5>
            <h2 className="heading">Analytics and Reporting</h2>
            <p className="text">
              Our team uses analytics to acquire crucial information and produce
              desired outcomes for your site. We offer regular consultations,
              email updates, and monthly reports to keep you updated about your
              marketing opportunities, limitations, and strengths.
            </p>
          </div>
          <div className="columnsearch image-column">
            <img
              className="image"
              src="https://thehumantek.com/wp-content/uploads/2023/08/Analytics-and-Reporting.png"
              alt="Keyword Strategy Planning"
              title="Keyword Strategy Planning"
            />
          </div>
        </div>
      </section>
    </>
  );
}

export default searchengineoptimization;
