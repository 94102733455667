import "./contactus.css";
import axios from "axios";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  // faMapMarkerAlt,
  faEnvelope,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";

function Contact() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const [showPopup, setShowPopup] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    axios
  .post("https://techiocracy.com/submit-form/submit-form", formData)
  .then((response) => {
    console.log("Form submitted successfully:", response.data);
    setShowPopup(true); // Show pop-up after successful submission
  })
  .catch((error) => {
    console.error("Error submitting form:", error);
    window.alert(
      "There was an error submitting the form. Please try again."
    );
  });

  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  return (
    <>
      <div
        className="companycontactus-container-fluid d-flex justify-content-center align-items-center"
        style={{ backgroundColor: "#007bff", color: "#fff", minHeight: "50vh" }}
      >
        <div className="companycontactus-text-center mt-5">
          <h1 className="mb-4">Contact Us</h1>
          <h5>Home | Contact Us</h5>
        </div>
      </div>

      <div className="companycontactus-contact-info">
        {/* <div className="companycontactus-info-box">
          <FontAwesomeIcon
            icon={faMapMarkerAlt}
            size="2x"
            className="companycontactus-icon"
          />
          <h2>Location</h2>
          <p>
            <Link to="https://maps.app.goo.gl/of6Af9N3enNQCsb76">
              1st Floor Office No A1, Rahat Jo Dero, Tariq Rd, Block 2 P.E.C.H.S.,
              Karachi, Karachi City, Sindh, Pakistan
            </Link>
          </p>
        </div> */}
        <div className="companycontactus-info-box">
          <FontAwesomeIcon
            icon={faEnvelope}
            size="2x"
            className="companycontactus-icon"
          />
          <h2>Email</h2>
          <p>
            <Link to="mailto:osama@techiocracy.com">osama@techiocracy.com</Link>
          </p>
        </div>
        <div className="companycontactus-info-box">
          <FontAwesomeIcon
            icon={faPhone}
            size="2x"
            className="companycontactus-icon"
          />
          <h2>Phone</h2>
          <p>
            <Link to="tel:+1 2063171388">+1 2063171388</Link>
          </p>
        </div>
      </div>

      <div className="companycontactus-contact-form-container">
        <form className="companycontactus-contact-form" onSubmit={handleSubmit}>
          <h2>Contact Us</h2>
          <div className="companycontactus-form-group">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              id="name"
              name="name"
              required
              value={formData.name}
              onChange={handleChange}
            />
          </div>
          <div className="companycontactus-form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              required
              value={formData.email}
              onChange={handleChange}
            />
          </div>
          <div className="companycontactus-form-group">
            <label htmlFor="phone">Phone</label>
            <input
              type="tel"
              id="phone"
              name="phone"
              required
              value={formData.phone}
              onChange={handleChange}
            />
          </div>

          <div className="companycontactus-form-group">
            <label htmlFor="message">Message</label>
            <textarea
              id="message"
              name="message"
              required
              value={formData.message}
              onChange={handleChange}
            ></textarea>
          </div>
          <button className="contactusbutton" type="submit">
            Submit
          </button>
        </form>
      </div>

      {showPopup && (
        <div className="popup-window">
        <div className="popup-content">
          <div className="popup-icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM6.97 11.03a.75.75 0 0 0 1.08 0l4.02-4.02a.75.75 0 1 0-1.06-1.06L7.5 9.94 5.53 7.97a.75.75 0 1 0-1.06 1.06l2.5 2.5z"/>
            </svg>
          </div>
          <h2>Form Submitted Successfully!</h2>
          <p>Thank you for getting in touch with us. We will respond soon.</p>
          <p>For any further inquiries, please contact <Link to="mailto:osama@techiocracy.com">osama@techiocracy.com</Link></p>
          <button onClick={handleClosePopup}>Close</button>
        </div>
      </div>
      
      )}
    </>
  );
}

export default Contact;
