import React from "react";

import { Container, Row, Col } from "react-bootstrap";
import CountUp from "react-countup";
import project from "../images/project.png";
import result from "../images/positive-result.webp";
import happy from "../images/happy-client.jpg";
import industry from "../images/industry.png";
import mission from "../images/mission.webp";
import vission from "../images/vission.webp";
import './ourcompany.css'

function services() {
  return (
    <>
      <div
        className="container-fluid d-flex justify-content-center align-items-center"
        style={{ backgroundColor: "#007bff", color: "#fff", minHeight: "50vh" }}
      >
        <div className="text-center mt-5">
          <h1 className="mb-4">Our Company</h1>
          <h5>Home | Our Company</h5>
        </div>
      </div>

      <div class="container-fluid bg-white text-dark py-2">
    <div class="row justify-content-center">
      <div class="col-md-8 text-center">
        <h3 class="h3">Embracing a Culture of Innovation</h3>
        <p class="mb-0">
          Our widespread stream of content and projects are completed on a
          daily basis. We offer multiple benefits to our customers in a
          variety of areas. Our services include web development and
          designing along with social media marketing.
        </p>
        <hr class="my-5" />
      </div>
    </div>
  </div>




      <div className="container py-4">
        <div className="row justify-content-center">
          {/* Column for Text Content */}
          <div className="col-md-6 animated fadeInRight pr-md-4">
            <div className="elementor-widget-wrap">
              <div className="elementor-element elementor-element-e2eaed4 elementor-widget elementor-widget-heading">
                <div className="elementor-widget-container">
                  <h2 className="elementor-heading-title elementor-size-default">
                    Our Vision
                  </h2>
                </div>
              </div>
              <div className="elementor-element elementor-element-e43e726 elementor-widget__width-auto elementor-widget elementor-widget-text-editor">
                <div className="elementor-widget-container">
                  <p>
                    Techiocracy aim is to be known as a trustworthy,
                    forward-thinking IT house with a welcoming environment,
                    enlarge the market, give clients straightforward IT
                    solutions, and amaze our clients with our services. We
                    strive to create new ideas and then implement them with
                    skill and ingenuity since we almost never fail to hit our
                    mark.
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Column for Image */}
          <div className="col-md-6 animated fadeInLeft pl-md-4">
            <div className="elementor-widget-wrap">
              <div className="elementor-element elementor-element-8d04dcc elementor-widget elementor-widget-image">
                <div className="elementor-widget-container">
                  <a
                    href="https://thehumantek.com/wp-content/uploads/2023/03/legogif.gif"
                    data-elementor-open-lightbox="yes"
                    data-elementor-lightbox-title="lego+gif"
                    data-e-action-hash="#elementor-action%3Aaction%3Dlightbox%26settings%3DeyJpZCI6MzE5OSwidXJsIjoiaHR0cHM6XC9cL3RoZWh1bWFudGVrLmNvbVwvd3AtY29udGVudFwvdXBsb2Fkc1wvMjAyM1wvMDNcL2xlZ29naWYuZ2lmIn0%3D"
                  >
                    <img
                      src={vission}
                      title="lego+gif"
                      alt="lego+gif"
                      className="elementor-animation-shrink img-fluid"
                      loading="lazy"
                      style={{ maxWidth: "100%", height: "auto" }}
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container py-4">
        <div className="row justify-content-center align-items-center">
          {/* Left Column - Image */}
          <div className="col-md-6 animated fadeInLeft pr-md-4">
            <div className="elementor-widget-wrap">
              <div className="elementor-element elementor-element-126a738 elementor-widget elementor-widget-image">
                <div className="elementor-widget-container">
                  <a
                    href="https://thehumantek.com/wp-content/uploads/2023/08/White-board.png"
                    data-elementor-open-lightbox="yes"
                    data-elementor-lightbox-title="White board"
                    data-e-action-hash="#elementor-action%3Aaction%3Dlightbox%26settings%3DeyJpZCI6NzIwNywidXJsIjoiaHR0cHM6XC9cL3RoZWh1bWFudGVrLmNvbVwvd3AtY29udGVudFwvdXBsb2Fkc1wvMjAyM1wvMDhcL1doaXRlLWJvYXJkLnBuZyJ9"
                  >
                    <img
                      src={mission}
                      title="White board"
                      alt="White board"
                      className="elementor-animation-shrink img-fluid"
                      loading="lazy"
                      style={{ maxWidth: "100%", height: "auto" }}
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>

          {/* Right Column - Text Content */}
          <div className="col-md-6 animated fadeInRight pl-md-4">
            <div className="elementor-widget-wrap">
              <div className="elementor-element elementor-element-101344d elementor-widget elementor-widget-heading">
                <div className="elementor-widget-container">
                  <h2 className="elementor-heading-title elementor-size-default">
                    Our Mission
                  </h2>
                </div>
              </div>
              <div className="elementor-element elementor-element-3e811fd elementor-widget__width-auto elementor-widget elementor-widget-text-editor">
                <div className="elementor-widget-container">
                  <p>
                    To provide impactful employment opportunities in Pakistan
                    and foster an atmosphere where our different talents can
                    converge to drive significant change through innovative
                    technologies and creative marketing solutions.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Container fluid className="py-0 bg-light text-black">
        <Row className="text-center">
          <Col xs={6} md={3}>
            <img
              src={project}
              alt="Projects"
              width="50"
              height="50"
              className="mb-3"
            />
            <h2>
              <CountUp end={10000} duration={2} />
              k+
            </h2>
            <p>Projects done</p>
          </Col>
          <Col xs={6} md={3}>
            <img
              src={result}
              alt="Positive Result"
              width="50"
              height="50"
              className="mb-3"
            />
            <h2>
              <CountUp end={100} duration={2} />%
            </h2>
            <p>Positive Result</p>
          </Col>
          <Col xs={6} md={3}>
            <img
              src={happy}
              alt="Happy Clients"
              width="50"
              height="50"
              className="mb-3"
            />
            <h2>
              <CountUp end={15000} duration={2} />
              K+
            </h2>
            <p>Happy Clients</p>
          </Col>
          <Col xs={6} md={3}>
            <img
              src={industry}
              alt="Industry Experts"
              width="50"
              height="50"
              className="mb-3"
            />
            <h2>
              <CountUp end={200} duration={2} />+
            </h2>
            <p>Industry Experts</p>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default services;
