import React from "react";
import logo1 from "../images/portfoliologo1.png";
import logo2 from "../images/portfoliologo2.png";
import logo3 from "../images/portfoliologo3.png";
import UI1 from "../images/UIUX1.png";
import UI2 from "../images/UIUX2.png";
import UI3 from "../images/UIUX3.png";
import "./portfolio.css";    
import Preproj from "../components/ourPreviousProjects";
import Wbanner from "../components/webbannerslider";

import { Link } from "react-router-dom";

// import Contentwritting1 from "../images/contentwritting1.png";

// import { Container, Row, Col, Image, Button } from "react-bootstrap";

function portfolio() {
  const logos = [logo1, logo2, logo3];
  const UIUX = [UI1, UI2, UI3];

  return (
    <>
      <div
        className="container-fluid d-flex justify-content-center align-items-center"
        style={{
          backgroundColor: "#007bff",
          color: "#fff",
          minHeight: "50vh",
        }}
      >
        <div className="text-center mt-5">
          <h1 className="mb-4">Our Portfolio</h1>
          <h5>Home | Our Portfolio</h5>
        </div>
      </div>

      <Preproj />

      <div className="App mb-4">
        <h1 className="py-4">Website Banners</h1>
        <Wbanner />
      </div>

      <div className="Appportfolio mt-5">
        <h1>Logo Design</h1>
        <div className="content-container">
          {logos.map((logo, index) => (
            <div key={index} className="logo-item">
              <img src={logo} alt={`Logo ${index + 1}`} />
            </div>
          ))}
        </div>
      </div>

      <div className="Appportfolio">
        <h1>UI/UX DESIGN</h1>
        <div className="content-container">
          {UIUX.map((uiux, index) => (
            <div key={index} className="logo-item">
              <img src={uiux} alt={`Logo ${index + 1}`} />
            </div>
          ))}
        </div>
      </div>

      <div className="mt-4 mb-5 text-center p-1">
        <h1>CONTENT WRITING SAMPLES</h1>
      </div>

      <section className="online-business-section">
        <div className="containerc">
          <div className="columnc">
            <div className="image-container fadeInLeft">
              <a
                href="https://thehumantek.com/wp-content/uploads/2023/04/online-business-ideas-to-start-in-pakistan.png"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="https://thehumantek.com/wp-content/uploads/2023/04/online-business-ideas-to-start-in-pakistan.png"
                  alt="Online Business Ideas"
                />
              </a>
            </div>
          </div>
          <div className="columnc">
            <div className="text-container  fadeInRight">
              <p>
                <strong>Online Business Ideas in Pakistan</strong>
              </p>
              <p>
                Since the Lockdown, working methods have evolved everywhere.
                Even though COVID-19 is now officially history, it transformed
                the way most people approach their professions. The majority in
                Pakistan wish to do so presently and prefer the convenience of
                our own houses. <br />
                If you want to start an online business but don’t know where to
                start, here are some options to choose from: <br />
                Create a YouTube channel; it can be about any theme, field, or
                industry.
              </p>
            </div>
            <div className="button-container fadeInUp">
              <Link className="button" to="/online-b">
                Read more{" "}
                <i className="fas fa-arrow-right" aria-hidden="true"></i>
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section className="online-business-section">
        <div className="containerc">
          <div className="columnc">
            <div className="image-container fadeInLeft">
              <a
                href="https://thehumantek.com/wp-content/uploads/2023/04/how-to-start-an-ecommerce-business-in-pakistan.jpg"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="https://thehumantek.com/wp-content/uploads/2023/04/how-to-start-an-ecommerce-business-in-pakistan.jpg"
                  alt="How to start an E-commerce business in Pakistan"
                />
              </a>
            </div>
          </div>
          <div className="columnc">
            <div className="text-container fadeInRight">
              <p>
                <strong>
                  How to start an E-commerce business in Pakistan?
                </strong>
              </p>
              <p>
                Online businesses have removed geographic boundaries in the
                retail industry. The shopping spree is transitioning from the
                physical to the internet rapidly. It is the right move for an
                entrepreneur to go with the flow and start focusing on
                e-commerce. In Pakistan, we have seen a shift in online
                shopping; people shop when bored or need something but don’t
                have the time and energy. <br />                Online retailers are creating new fashion-related items and
                designs, enabling customers to order from anywhere in the world.
              </p>
            </div>
            <div className="button-container fadeInUp">
              <Link className="button" to="/ecommerce">
                Read more{" "}
                <i className="fas fa-arrow-right" aria-hidden="true"></i>
              </Link>
            </div>
          </div>
        </div>
      </section>

      <div className="mt-4 mb-5 text-center">
        <h1>SMM RESULTS</h1>
      </div>
      <section className="seo-section">
        <div className="container">
          <div className="inner-container">
            <div className="column fadeInLeft">
              <div className="image-container">
                <a
                  href="https://thehumantek.com/wp-content/uploads/2023/05/image-3.png"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="https://thehumantek.com/wp-content/uploads/2023/05/image-3.png"
                    alt="SEO"
                  />
                </a>
              </div>
            </div>
            <div className="column fadeInRight">
              <div className="text-container">
                <h5 className="seotext">SEO</h5>
                <h2>BEFORE SEO</h2>
                <p>
                  Are you tired of seeing lower rankings? Without SEO, your
                  website will not rank well in search engine results pages
                  (SERPs), making it difficult for potential customers to find
                  you. Not utilizing SEO means you’re missing out on the
                  opportunity to optimize your website and content for search
                  engines.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="after-seo-section p-3">
      <div className="container">
        <div className="inner-container">
          <div className="column fadeInLeft">
            <div className="text-container">
              <h5 className="seotext">SEO</h5>
              <h2>AFTER SEO</h2>
              <p>
                Using SEO (Search Engine Optimization) will provide numerous benefits for businesses. 
                It will increase the visibility and ranking of your website in search engine results pages (SERPs). 
                SEO provides a high return on investment (ROI) and as part of your digital marketing strategy 
                can help drive growth, increase brand awareness, and improve your bottom line.
              </p>
            </div>
          </div>
          <div className="column fadeInRight">
            <div className="image-container">
              <a
                href="https://thehumantek.com/wp-content/uploads/2023/04/op.jpeg"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="https://thehumantek.com/wp-content/uploads/2023/04/op.jpeg"
                  alt="SEO Benefits"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
    </>
  );
}

export default portfolio;