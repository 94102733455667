import React from "react";

function graphicDesign() {
  return (
    <>
      <div
        className="container-fluid d-flex justify-content-center align-items-center"
        style={{ backgroundColor: "#007bff", color: "#fff", minHeight: "50vh" }}
      >
        <div className="text-center mt-5">
          <h1 className="mb-4">Graphic Designing</h1>
          <h5>Home | Graphic Designing</h5>
        </div>
      </div>

      <div className="container-fluid bg-white text-dark py-5 font-bootstrap">
        <div className="row justify-content-center">
          <div className="col-md-8 text-center">
            <p className="mb-5">
            Techiocracy offers splendid graphic designing services at affordable
              rates in Pakistan. Our talented designers can create high-quality
              graphics and branding services for you. Catch up with our skilled
              graphic designers to turn your ideas into a professional marketing
              product.
            </p>
            <p>
              We talk and listen to all of your dream ideas to figure out what
              is feasible, affordable, and also fits your budget. We can also
              assist you if you are starting a new business and want to
              establish your identity.
            </p>

            <p className="lead py-4">Have a look at our services</p>
            <hr className="my-5 hr-under-lead" />
          </div>
        </div>
      </div>

      <div className="container py-4">
        <div className="row align-items-center">
          {/* Column 1 - Image */}
          <div className="col-lg-6 animated fadeInLeft pr-lg-4">
            <div className="elementor-widget-wrap">
              <div className="elementor-element elementor-element-0ffeec0 elementor-widget elementor-widget-image">
                <div className="elementor-widget-container">
                  <a href="https://thehumantek.com/wp-content/uploads/2023/01/2.png" data-elementor-open-lightbox="yes" data-elementor-lightbox-title="2.png" data-e-action-hash="#elementor-action%3Aaction%3Dlightbox%26settings%3DeyJpZCI6NTI4LCJ1cmwiOiJodHRwczpcL1wvdGhlaHVtYW50ZWsuY29tXC93cC1jb250ZW50XC91cGxvYWRzXC8yMDIzXC8wMVwvMi5wbmcifQ%3D%3D">
                    <img
                      src="https://thehumantek.com/wp-content/uploads/2023/01/2.png"
                      className="elementor-animation-shrink img-fluid"
                      alt=""
                      width="300"
                      height="300"
                      srcSet="https://thehumantek.com/wp-content/uploads/2023/01/2.png 500w, https://thehumantek.com/wp-content/uploads/2023/01/2-300x300.png 300w, https://thehumantek.com/wp-content/uploads/2023/01/2-150x150.png 150w"
                      sizes="(max-width: 500px) 100vw, 500px"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>

          {/* Column 2 - Text Content */}
          <div className="col-lg-6 animated fadeInRight pl-lg-4">
            <div className="elementor-widget-wrap">
              <div className="elementor-element elementor-element-794b371 elementor-widget elementor-widget-heading">
                <div className="elementor-widget-container">
                  <h2 className="elementor-heading-title elementor-size-default">Versatile Logo Designing</h2>
                </div>
              </div>
              <div className="elementor-element elementor-element-777ff53 elementor-widget elementor-widget-heading">
                <div className="elementor-widget-container">
                  <h2 className="elementor-heading-title elementor-size-default">CUSTOM LOGO DESIGN</h2>
                </div>
              </div>
              <div className="elementor-element elementor-element-13b6f63 elementor-widget__width-auto elementor-widget elementor-widget-text-editor">
                <div className="elementor-widget-container">
                  <p>A logo represents a specific organization or business by creating a visual image that is easily understood and recognized. Our designers approach each custom logo design project with zeal and devotion.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="container py-4">
        <div className="row align-items-center">
          {/* Right Column */}
          <div className="col-lg-6 animated fadeInRight">
            <div className="elementor-widget-wrap elementor-element-populated">
              <div className="elementor-element elementor-element-55053d8 elementor-widget elementor-widget-heading">
                <div className="elementor-widget-container">
                  <h2 className="elementor-heading-title elementor-size-default">High-Quality</h2>
                </div>
              </div>
              <div className="elementor-element elementor-element-4fa6b05 elementor-widget elementor-widget-heading">
                <div className="elementor-widget-container">
                  <h2 className="elementor-heading-title elementor-size-default">MARKETING COLLATERAL DESIGN</h2>
                </div>
              </div>
              <div className="elementor-element elementor-element-0e6c6fd elementor-widget__width-auto elementor-widget elementor-widget-text-editor">
                <div className="elementor-widget-container">
                  <p>Marketing collateral can have a notable impact on your company’s image. We offer appealing marketing collateral design services to ensure your company’s business cards, visiting cards, envelopes, brochures, letterheads, and flyers stand out in every sales meeting, face-to-face experience, and direct mailing.</p>
                </div>
              </div>
            </div>
          </div>

          {/* Left Column */}
          <div className="col-lg-6 animated fadeInLeft">
            <div className="elementor-widget-wrap elementor-element-populated">
              <div className="elementor-element elementor-element-70f57c0 elementor-widget elementor-widget-image">
                <div className="elementor-widget-container">
                  <a
                    href="https://thehumantek.com/wp-content/uploads/2023/03/Asset-2-1.png"
                    data-elementor-open-lightbox="yes"
                    data-elementor-lightbox-title="Asset 2 1"
                    data-e-action-hash="#elementor-action%3Aaction%3Dlightbox%26settings%3DeyJpZCI6NDEwMSwidXJsIjoiaHR0cHM6XC9cL3RoZWh1bWFudGVrLmNvbVwvd3AtY29udGVudFwvdXBsb2Fkc1wvMjAyM1wvMDNcL0Fzc2V0LTItMS5wbmcifQ%3D%3D"
                  >
                    <img
                      decoding="async"
                      width="300"
                      height="300"
                      src="https://thehumantek.com/wp-content/uploads/2023/03/Asset-2-1.png"
                      className="elementor-animation-shrink attachment-full size-full wp-image-4101"
                      alt=""
                      srcSet="https://thehumantek.com/wp-content/uploads/2023/03/Asset-2-1.png 779w, https://thehumantek.com/wp-content/uploads/2023/03/Asset-2-1-300x235.png 300w, https://thehumantek.com/wp-content/uploads/2023/03/Asset-2-1-768x600.png 768w"
                      sizes="(max-width: 779px) 100vw, 779px"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="container py-4">
        <div className="row align-items-center">
          {/* Left Column */}
          <div className="col-lg-6 animated fadeInLeft">
            <div className="elementor-widget-wrap elementor-element-populated">
              <div className="elementor-element elementor-element-6030fe4 elementor-widget elementor-widget-image">
                <div className="elementor-widget-container">
                  <a
                    href="https://thehumantek.com/wp-content/uploads/2023/01/Untitled-700-×-500-px-1-2.png"
                    data-elementor-open-lightbox="yes"
                    data-elementor-lightbox-title="Untitled-700-×-500-px-1.png"
                    data-e-action-hash="#elementor-action%3Aaction%3Dlightbox%26settings%3DeyJpZCI6NTMxLCJ1cmwiOiJodHRwczpcL1wvdGhlaHVtYW50ZWsuY29tXC93cC1jb250ZW50XC91cGxvYWRzXC8yMDIzXC8wMVwvVW50aXRsZWQtNzAwLVx1MDBkNy01MDAtcHgtMS0yLnBuZyJ9"
                  >
                    <img
                      decoding="async"
                      width="300"
                      height="300"
                      src="https://thehumantek.com/wp-content/uploads/2023/01/Untitled-700-×-500-px-1-2.png"
                      className="elementor-animation-shrink attachment-full size-full wp-image-531"
                      alt=""
                      srcSet="https://thehumantek.com/wp-content/uploads/2023/01/Untitled-700-×-500-px-1-2.png 700w, https://thehumantek.com/wp-content/uploads/2023/01/Untitled-700-×-500-px-1-2-300x214.png 300w"
                      sizes="(max-width: 700px) 100vw, 700px"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>

          {/* Right Column */}
          <div className="col-lg-6 animated fadeInRight">
            <div className="elementor-widget-wrap elementor-element-populated">
              <div className="elementor-element elementor-element-2f90b2c elementor-widget elementor-widget-heading">
                <div className="elementor-widget-container">
                  <h2 className="elementor-heading-title elementor-size-default">Practical Functionality</h2>
                </div>
              </div>
              <div className="elementor-element elementor-element-7a4863d elementor-widget elementor-widget-heading">
                <div className="elementor-widget-container">
                  <h2 className="elementor-heading-title elementor-size-default">PACKAGING DESIGN</h2>
                </div>
              </div>
              <div className="elementor-element elementor-element-30ec90c elementor-widget__width-auto elementor-widget elementor-widget-text-editor">
                <div className="elementor-widget-container">
                  <p>Every great product requires a great packaging design. Shape, color palette, typography, and material are all combined signs that make packaging visible and understandable to the consumer. We provide outstanding packaging design services that will help you increase your sales and improve your company prospects.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="container py-4">
        <div className="row align-items-center">
          {/* Right Column */}
          <div className="col-lg-6 animated fadeInRight">
            <div className="elementor-widget-wrap elementor-element-populated">
              <div className="elementor-element elementor-element-2916384 elementor-widget elementor-widget-heading">
                <div className="elementor-widget-container">
                  <h2 className="elementor-heading-title elementor-size-default">Eye-Catching</h2>
                </div>
              </div>
              <div className="elementor-element elementor-element-1acb420 elementor-widget elementor-widget-heading">
                <div className="elementor-widget-container">
                  <h2 className="elementor-heading-title elementor-size-default">BANNER DESIGNS</h2>
                </div>
              </div>
              <div className="elementor-element elementor-element-045db26 elementor-widget__width-auto elementor-widget elementor-widget-text-editor">
                <div className="elementor-widget-container">
                  <p>Good banner designs convey your brand’s concept, enticing viewers to click on the ad and learn more about you. We offer high-quality custom banner designs that are trusted and adored by people across Pakistan.</p>
                </div>
              </div>
            </div>
          </div>

          {/* Left Column */}
          <div className="col-lg-6 animated fadeInLeft">
            <div className="elementor-widget-wrap elementor-element-populated">
              <div className="elementor-element elementor-element-2c0354b elementor-widget elementor-widget-image">
                <div className="elementor-widget-container">
                  <a
                    href="https://thehumantek.com/wp-content/uploads/2023/03/Group-8974.png"
                    data-elementor-open-lightbox="yes"
                    data-elementor-lightbox-title="Group 8974"
                    data-e-action-hash="#elementor-action%3Aaction%3Dlightbox%26settings%3DeyJpZCI6NDA5NiwidXJsIjoiaHR0cHM6XC9cL3RoZWh1bWFudGVrLmNvbVwvd3AtY29udGVudFwvdXBsb2Fkc1wvMjAyM1wvMDNcL0dyb3VwLTg5NzQucG5nIn0%3D"
                  >
                    <img
                      loading="lazy"
                      decoding="async"
                      width="300"
                      height="300"
                      src="https://thehumantek.com/wp-content/uploads/2023/03/Group-8974.png"
                      className="elementor-animation-shrink attachment-full size-full wp-image-4096"
                      alt=""
                      srcSet="https://thehumantek.com/wp-content/uploads/2023/03/Group-8974.png 851w, https://thehumantek.com/wp-content/uploads/2023/03/Group-8974-300x183.png 300w, https://thehumantek.com/wp-content/uploads/2023/03/Group-8974-768x467.png 768w"
                      sizes="(max-width: 851px) 100vw, 851px"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container py-4 mb-5">
        <div className="row align-items-center">
          {/* Left Column */}
          <div className="col-lg-6 animated fadeInLeft">
            <div className="elementor-widget-wrap elementor-element-populated">
              <div className="elementor-element elementor-element-1a1cea2 elementor-widget elementor-widget-image">
                <div className="elementor-widget-container">
                  <a
                    href="https://thehumantek.com/wp-content/uploads/2023/03/Asset-1-1-1.png"
                    data-elementor-open-lightbox="yes"
                    data-elementor-lightbox-title="Asset 1 1"
                    data-e-action-hash="#elementor-action%3Aaction%3Dlightbox%26settings%3DeyJpZCI6NDA5NywidXJsIjoiaHR0cHM6XC9cL3RoZWh1bWFudGVrLmNvbVwvd3AtY29udGVudFwvdXBsb2Fkc1wvMjAyM1wvMDNcL0Fzc2V0LTEtMS0xLnBuZyJ9"
                  >
                    <img
                      decoding="async"
                      src="https://thehumantek.com/wp-content/uploads/2023/03/Asset-1-1-1.png"
                      title="Asset 1 1"
                      alt="Asset 1 1"
                      className="elementor-animation-shrink"
                      loading="lazy"
                      width="400"
                      height="300"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>

          {/* Right Column */}
          <div className="col-lg-6 animated fadeInRight">
            <div className="elementor-widget-wrap elementor-element-populated">
              <div className="elementor-element elementor-element-d88c9b6 elementor-widget elementor-widget-heading">
                <div className="elementor-widget-container">
                  <h2 className="elementor-heading-title elementor-size-default">Easy to Understand</h2>
                </div>
              </div>
              <div className="elementor-element elementor-element-8550345 elementor-widget elementor-widget-heading">
                <div className="elementor-widget-container">
                  <h2 className="elementor-heading-title elementor-size-default">INFOGRAPHICS</h2>
                </div>
              </div>
              <div className="elementor-element elementor-element-5a64c40 elementor-widget__width-auto elementor-widget elementor-widget-text-editor">
                <div className="elementor-widget-container">
                  <p>Infographics are a popular online marketing tool for simplifying complex data and capturing viewers’ attention. Our Infographic Design Services include the creation of informative and appealing infographic designs to meet the needs of your online business and increase brand awareness.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </>
  );
}

export default graphicDesign;
