import React from "react";
import Logo from "../images/logobg.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram, faFacebook, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import './footer.css'
import { Link } from "react-router-dom";

function Footer() {
  return (
    <>
      <footer className="bg-black text-light mt-0">
        <div className="container text-center py-5">
          <h1>Our Mission</h1>
          <p className="lead py-2 mb-0">
            Our mission is simple yet powerful: to empower businesses and individuals with cutting-edge digital solutions.
            <br />
            We believe that every interaction with technology should be intuitive, engaging, and efficient.
            <br />
            Through our wide range of services and unwavering commitment to innovation,
            <br />
            we aim to make this belief a reality.
            <br />
          </p>
        </div>

        <div className="text-center">
          <img src={Logo} className="rounded" alt="Website Logo" />
        </div>

        <h3 className="text-center">
          Make your life smart and get innovative with new technologies
        </h3>

        <div className="d-flex justify-content-center">
          <div className="social-icons">
            <Link to="https://www.linkedin.com/company/techiocracy/" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon
                icon={faLinkedin}
                size="2x"
                style={{ color: "white", marginRight: "20px" }}
              />
            </Link>
            <Link to="https://www.facebook.com/profile.php?id=61561701465596&mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon
                icon={faFacebook}
                size="2x"
                style={{ color: "white", marginRight: "20px" }}
              />
            </Link>
            <Link to="https://www.instagram.com/techiocracy?igsh=MW9uYjVoaHU3cTBqeA==" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faInstagram} size="2x" style={{ color: "white" }} />
            </Link>
          </div>
        </div>

        <div className="container my-3">
          <div className="row align-items-start" >
            <div className="col-md-4" >
              <h5>Contact</h5>
              <div className="contact-icons">
                <div className="icon-container my-4">
                  <FontAwesomeIcon
                    icon={faPhone}
                    size="2x"
                    style={{ color: "white", marginRight: "10px" }}
                  />
                  <span className="icon-text">
                    <a href="tel:+12063171388" style={{ color: "white" }}>
                      +1 2063171388
                    </a>
                  </span>
                </div>
                <div className="icon-container d-flex">
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    size="2x"
                    style={{ color: "white", marginRight: "10px" }}
                  />
                  <span className="icon-text">
                    <a href="mailto:osama@techiocracy.com" style={{ color: "white"}}>
                      osama@techiocracy.com
                    </a>
                  </span>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <h5>Quick Links</h5>
              <ul className="list-unstyled" style={{ listStyleType: "none" }}>
                <li className="mb-3">
                  <Link to="/" style={{ textDecoration: "none", color: "white" }}>
                    Home
                  </Link>
                </li>
                <li className="mb-3">
                  <Link to="/our-company" style={{ textDecoration: "none", color: "white" }}>
                    Our Company
                  </Link>
                </li>
                <li className="mb-3">
                  <Link to="/portfolio" style={{ textDecoration: "none", color: "white" }}>
                    Our Portfolio
                  </Link>
                </li>
                <li className="mb-3">
                  <Link to="/packages" style={{ textDecoration: "none", color: "white" }}>
                    Packages
                  </Link>
                </li>
              </ul>
            </div>

            <div className="col-md-4">
              <h5>Our Services</h5>
              <ul className="list-unstyled">
                <li className="mb-2">
                  <Link to="/software-development" style={{ textDecoration: "none", color: "white" }}>
                    Software Development
                  </Link>
                </li>
                <li className="mb-2">
                  <Link to="/animation" style={{ textDecoration: "none", color: "white" }}>
                    2D & 3D Animation
                  </Link>
                </li>
                <li className="mb-2">
                  <Link to="/graphic-designing" style={{ textDecoration: "none", color: "white" }}>
                    Graphic Designing
                  </Link>
                </li>
                <li className="mb-2">
                  <Link to="/web-development" style={{ textDecoration: "none", color: "white" }}>
                    Web Development
                  </Link>
                </li>
                <li className="mb-2">
                  <Link to="/mobile-app-development" style={{ textDecoration: "none", color: "white" }}>
                    Mobile App Development
                  </Link>
                </li>
                <li className="mb-2">
                  <Link to="/digital-marketing" style={{ textDecoration: "none", color: "white" }}>
                    Digital Marketing
                  </Link>
                </li>
                <li className="mb-2">
                  <Link to="/seo" style={{ textDecoration: "none", color: "white" }}>
                    Search Engine Optimization
                  </Link>
                </li>
                <li className="mb-2">
                  <Link to="/ai" style={{ textDecoration: "none", color: "white" }}>
                    Artificial Intelligence
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <hr />
        </div>

        <div className="text-center py-1">
          <p>
            Copyright &copy; {new Date().getFullYear()} Your Company. All rights reserved.
          </p>
        </div>
      </footer>
    </>
  );
}

export default Footer;
