import heroImage from "../images/hero-image.png";
// import icon3 from "../images/market-research.png";
// import icon4 from "../images/business-grow.jpg";
// import icon5 from "../images/improve-idea.webp";
// import icon6 from "../images/marketing.png";
// import leftimg from "../images/third-sec-left.png";
// import suvage from "../images/vector.png";
import icon7 from "../images/pi-chart.png";
import tick from "../images/tick.png";
// import centerimg from "../images/services-we-provide.png";
// import onecard from "../images/osamaa.webp";
// import twocard from "../images/Waqar.webp";
// import threecard from "../images/three-card.png";
// import fourcard from "../images/four-card.jpeg";
// import Faqs from "./Faqs";
// import osama from "../images/osamaa.webp";
// import cardbottom from "../images/card-bottom.jpg";
// import clandersvg from "../images/calenderimg.png";
// import "../components/home.css";

import "../components/home.css";

// import backgoundimage from '../images/background.gif'
// import comment from '../images/comment-svg.png'
// import Slider from '../components/comp'
// import mob from '../images/mobile-background.jpg'
// ascjnacskn
// import Faqs from './Faqs'
import Cards from "./card";
// import Team from './our-team'

import { Link } from "react-router-dom";

import axios from "axios";

import React, { useState } from "react";

// import logo1 from '../images/portfoliologo1.png';
// import logo2 from '../images/portfoliologo2.png';
// import logo3 from '../images/portfoliologo3.png';
import UI1 from "../images/UIUX1.png";
import UI2 from "../images/UIUX2.png";
import UI3 from "../images/UIUX3.png";
import s1 from "../images/socialmedia1.jpg";
import s2 from "../images/socialmedia2.jpg";
import s3 from "../images/socialmedia3.jpg";
import offbadge from "../images/offbadge.png";

import "./uiux.css";
// import Sidebar from "./ChatIcon";
import Sidebarcall from "./ChatIconCall";

// import AnimatedSection from './animations';

// import AnimatedSection from './components/AnimatedSection';

// import Project from "./projects";
import WBannerslider from "./webbannerslider";

import Toolslider from "../components/toolsslider";

// form code start
// const MyForm = () => {

// form code end

function App() {
  // const logos = [logo1, logo2, logo3];
  const UIUX = [UI1, UI2, UI3];
  const SocialMedia = [s1, s2, s3];

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        "https://techiocracy.com/submit-form/submit-form",
        formData
      );
      // alert(response.data.message);
      setIsPopupVisible(true); // Show pop-up window
    } catch (error) {
      console.error("There was an error submitting the form!", error);
    }
  };

  const closePopup = () => {
    setIsPopupVisible(false);
  };

  return (
    <>
      {/* sidebar start  */}

      {/* <div className="right-chat-us-wrapper" style={{marginTop:'7em'}}>
              <a href="https://" className="chat-with-us-wrap d-flex" style={{paddingLeft:'1em'}}>
              <div className="img-chat">
                <img src={comment} alt="" style={{width:'35px'}}/>
              </div>
              <div className="chat-us-heading">
                <Link to="mailto:osama@techiocracy.com">
                  <h2 className="chat-us" style={{marginLeft:'.5em'}}>
                    Chat With Us</h2>
                    </Link>
              </div>
              </a>
          </div> */}

      {/* <div className="right-chat-us-wrapper" style={{marginTop:'13em'}}>
              <a href="https://" className="chat-with-us-wrap d-flex" style={{paddingLeft:'1em'}}>
              <div className="img-chat  mymove float">
                <img  src={comment} alt="" style={{width:'35px'}}/>
              </div>
              <div className="chat-us-heading">
                  <h2 className="chat-us" style={{marginLeft:'.5em'}}>Chat With Us</h2>
              </div>
              </a>
          </div>  */}

      {/* sidebar end */}

      {/* <Sidebar/> */}
      <Sidebarcall />
      <div className="text-light bg-section">
        <div className="container m-t">
          {/* <AnimatedSection animation=""> */}
          <div className="row align-items-center forquery">
            {/* Left Column */}
            <div className="col-md-6 animate__animated animate__fadeInLeft">
              <div className="content">
                <h1 className="text-dark-50 animate__animated animate__fadeInLeft home-font">
                  <span
                    className="yellow"
                    style={{ color: "rgb(255 255 255)" }}
                  >
                    Web Designs Starts <br />
                    from{" "}
                  </span>
                  $149 ONLY
                </h1>
                <ul>
                  <li>
                    <h3>Logo Designing 20$</h3>
                  </li>
                  <li>
                    <h3>Social Media Marketing 100$</h3>
                  </li>
                </ul>
                <div
                  className="hato-col d-flex"
                  style={{ margin: "1em 0 0 0em" }}
                >
                  <div className="tick">
                    <img src={tick} alt="" style={{ width: "18px" }} />
                  </div>
                  <div className="text text-white">
                    <p className="p" style={{ margin: "0em 0 0 1em" }}>
                      Expand Your Online Presence Globally
                    </p>
                  </div>
                </div>
                <div
                  className="hato-col d-flex"
                  style={{ margin: "1em 0 0 0em" }}
                >
                  <div className="tick">
                    <img src={tick} alt="" style={{ width: "18px" }} />
                  </div>
                  <div className="text text-white">
                    <p className="p" style={{ margin: "0em 0 0 1em" }}>
                      Crafting Responsive, User-Centric Websites
                    </p>
                  </div>
                </div>
                <div
                  className="hato-col d-flex"
                  style={{ margin: "1em 0 0 0em" }}
                >
                  <div className="tick">
                    <img src={tick} alt="" style={{ width: "18px" }} />
                  </div>
                  <div className="text text-white">
                    <p className="p" style={{ margin: "0em 0 0 1em" }}>
                      Connect, Expand, and Maintain Your Audience
                    </p>
                  </div>
                </div>
                <div
                  className="hato-col d-flex"
                  style={{ margin: "1em 0 2em 0em" }}
                >
                  <div className="tick">
                    <img src={tick} alt="" style={{ width: "18px" }} />
                  </div>
                  <div className="text text-white">
                    <p className="p" style={{ margin: "0em 0 0 1em" }}>
                      Enjoy Full 100% Ownership
                    </p>
                  </div>
                </div>
                <div className="d-flex button-main-wrap">
                  <Link
                    to="mailto:osama@techiocracy.com"
                    className="hero-button"
                  >
                    GET STARTED
                  </Link>
                  <Link
                    to="https://wa.me/message/ZNKOM6Y25T2XO1"
                    className="hero-button button-left"
                  >
                    CHAT WITH US
                  </Link>
                </div>
              </div>
            </div>

            {/* Right Column */}
            <div
              className="col-md-6 animate__animated animate__fadeInRight"
              style={{ position: "relative" }}
            >
              <div
                className="containerr"
                style={{
                  borderRadius: "10px",
                  padding: "20px",
                }}
              >
                <div className="row justify-content-center">
                  <div className="col-md-8">
                    <div
                      className="d-none d-md-block"
                      style={{
                        position: "absolute",
                        top: "-10px",
                        right: "80px",
                        width: "120px",
                        height: "120px",
                      }}
                    >
                      <img
                        src={offbadge}
                        alt="70% Off Badge"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "contain",
                        }}
                      />
                    </div>
                    <form className="my-form" onSubmit={handleSubmit}>
                      <div className="form-head">
                        <h2 className="text-center home-form-heading">
                          Let's Get Started
                        </h2>
                      </div>
                      <h4
                        className="text-center form-sub-heading"
                        style={{ color: "black" }}
                      >
                        Get{" "}
                        <span className="seven" style={{ color: "#f91c1d" }}>
                          70 %
                        </span>{" "}
                        Off on Your First Order
                      </h4>

                      <div className="form-group">
                        <input
                          type="text"
                          name="name"
                          className="form-control"
                          placeholder="Your Name"
                          value={formData.name}
                          onChange={handleChange}
                        />
                      </div>

                      <div className="form-group">
                        <input
                          type="email"
                          name="email"
                          className="form-control"
                          placeholder="Your Email"
                          value={formData.email}
                          onChange={handleChange}
                        />
                      </div>

                      <div className="form-group">
                        <input
                          type="tel"
                          name="phone"
                          className="form-control"
                          placeholder="Your Phone Number"
                          value={formData.phone}
                          onChange={handleChange}
                        />
                      </div>

                      <div className="form-group">
                        <textarea
                          name="message"
                          className="form-control"
                          rows="3"
                          placeholder="Enter your message"
                          value={formData.message}
                          onChange={handleChange}
                        ></textarea>
                      </div>

                      <div className="form-group text-center">
                        <button type="submit" className="hero-button">
                          GET STARTED
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* </AnimatedSection> */}
        </div>
        <Toolslider />
      </div>

      {/* Pop-up Window */}
      {isPopupVisible && (
        <div className="popup-overlay">
          <div className="popup-content">
            <div className="popup-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="48"
                height="48"
                fill="currentColor"
                className="bi bi-check-circle-fill"
                viewBox="0 0 16 16"
              >
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM6.97 11.03a.75.75 0 0 0 1.08 0l4.02-4.02a.75.75 0 1 0-1.06-1.06L7.5 9.94 5.53 7.97a.75.75 0 1 0-1.06 1.06l2.5 2.5z" />
              </svg>
            </div>
            <h3>Thank you for getting started!</h3>
            <p>We will get in touch with you shortly.</p>
            <p>
              For any further inquiries, please contact{" "}
              <Link to="mailto:osama@techiocracy.com">
                osama@techiocracy.com
              </Link>
            </p>
            <button onClick={closePopup} className="hero-button btn-primary">
              Close
            </button>
          </div>
        </div>
      )}

      <div className="anim-img">
        <div className="container text-aign-center py-5 ">
          {/* Top Row */}
          <div className="row">
            <div className="col-12 text-center">
              <h3 className="py-3 wh">OUR AREA OF EXPERTISE</h3>
              <h1 className="wh">Services Offered!</h1>
              <p className="py-4 wh">
                We provide creative solutions to our clients across various
                domains, encompassing development <br /> and design, as well as
                media marketing, optimization, and branding.
              </p>
            </div>
          </div>

          {/* Three Rows with Three Columns Each */}
          <div className="container">
            <div className="row">
              {/* First Column */}
              <div className="col-md-4 tt">
                <div className="content con">
                  <img
                    src={icon7}
                    width="60"
                    className="img-fluid mb-3"
                    alt="Small"
                  />
                  <h3 className="wh">
                    2D & 3D <br /> Animation
                  </h3>
                  <p className="wh">
                    At Techiocracy, we provide exceptional video animation
                    services in Pakistan that exemplify professionalism,
                    creativity, and uniqueness. Through our exceptional video
                    animation services, we strategically present your brand,
                    animate messages, and deliver impactful content to your
                    target audience.
                  </p>
                  <Link to="/animation" className="btn btn-primary wh">
                    Read More
                  </Link>
                </div>
              </div>

              {/* Second Column */}
              <div className="col-md-4 tt">
                <div className="content con">
                  <img
                    src={icon7}
                    width="60"
                    className="img-fluid mb-3"
                    alt="Small"
                  />
                  <h3 className="wh">
                    Mobile <br /> Development
                  </h3>
                  <p className="wh">
                    Crafting user-friendly native applications for Android and
                    iOS with features like push notifications and in-app
                    messaging. Post-launch support for a seamless user
                    experience.
                  </p>
                  <Link
                    to="/mobile-app-development"
                    className="btn btn-primary wh"
                  >
                    Read More
                  </Link>
                </div>
              </div>

              {/* Third Column */}
              <div className="col-md-4 tt">
                <div className="content con">
                  <img
                    src={icon7}
                    width="60"
                    className="img-fluid mb-3"
                    alt="Small"
                  />
                  <h3 className="wh">
                    Graphic <br />
                    Designing
                  </h3>
                  <p className="wh">
                    Techiocracy offers outstanding graphic design services at
                    competitive rates in Pakistan. Our talented team of
                    designers creates high-quality graphics and branding
                    solutions. Collaborate with our skilled graphic designers to
                    bring your ideas to life with professional marketing
                    materials.
                  </p>
                  <Link to="/graphic-designing" className="btn btn-primary wh">
                    Read More
                  </Link>
                </div>
              </div>
              <div className="col-md-4 tt">
                <div className="content con">
                  <img
                    src={icon7}
                    width="60"
                    className="img-fluid mb-3"
                    alt="Small"
                  />
                  <h3 className="wh">
                    SEO
                    <br />
                    Optimization
                  </h3>
                  <p className="wh">
                    Thorough keyword research and on-page optimization to boost
                    organic visibility. Developing and overseeing strategic
                    campaigns across top platforms with ongoing optimization
                    efforts.
                  </p>
                  <Link to="/seo" className="btn btn-primary wh">
                    Read More
                  </Link>
                </div>
              </div>

              {/* Second Column */}
              <div className="col-md-4 tt">
                <div className="content con">
                  <img
                    src={icon7}
                    width="60"
                    className="img-fluid mb-3"
                    alt="Small"
                  />
                  <h3 className="wh">
                    Digital
                    <br />
                    Marketing
                  </h3>
                  <p className="wh">
                    With an effective digital marketing strategy, your business
                    can lead the competition and thrive in the current digital
                    era.
                  </p>
                  <Link to="/digital-marketing" className="btn btn-primary wh">
                    Read More
                  </Link>
                </div>
              </div>

              {/* Third Column */}
              <div className="col-md-4 tt">
                <div className="content con">
                  <img
                    src={icon7}
                    width="60"
                    className="img-fluid mb-3"
                    alt="Small"
                  />
                  <h3 className="wh">
                    Web <br />
                    Development
                  </h3>
                  <p className="wh">
                    Development of dynamic, mobile-responsive websites that
                    align with your brand ethos Catering to various industries
                    with a focus on user interaction, speed, and cross-browser
                    compatibility.
                  </p>
                  <Link to="/web-development" className="btn btn-primary wh">
                    Read More
                  </Link>
                </div>
              </div>
              {/* start */}

              {/* First Column */}
              <div className="col-md-4 tt">
                <div className="content con">
                  <img
                    src={icon7}
                    width="60"
                    className="img-fluid mb-3"
                    alt="Small"
                  />
                  <h3 className="wh">
                    Software <br /> Development
                  </h3>
                  <p className="wh">
                    At Techiocracy, we provide exceptional video animation
                    services in Pakistan that exemplify professionalism,
                    creativity, and uniqueness. Through our exceptional video
                    animation services, we strategically present your brand,
                    animate messages, and deliver impactful content to your
                    target audience.
                  </p>
                  <Link
                    to="/software-development"
                    className="btn btn-primary wh"
                  >
                    Read More
                  </Link>
                </div>
              </div>

              {/* Second Column */}
              <div className="col-md-4 tt">
                <div className="content con">
                  <img
                    src={icon7}
                    width="60"
                    className="img-fluid mb-3"
                    alt="Small"
                  />
                  <h3 className="wh">
                    Artificial <br /> Intelligence
                  </h3>
                  <p className="wh">
                    Techiocracy specializes in delivering advanced AI services,
                    utilizing state-of-the-art technologies to provide
                    customized solutions aligned with your business goals.
                  </p>
                  <Link to="/ai" className="btn btn-primary wh">
                    Read More
                  </Link>
                </div>
              </div>

              {/* Third Column */}
              <div className="col-md-4 tt">
                <div className="content con">
                  <img
                    src={icon7}
                    width="60"
                    className="img-fluid mb-3"
                    alt="Small"
                  />
                  <h3 className="wh">
                    Blockchain <br />
                    Technology
                  </h3>
                  <p className="wh">
                    Techiocracy excels in providing comprehensive blockchain
                    services, leveraging state-of-the-art technologies to
                    deliver tailored solutions that meet your business needs.
                  </p>
                  <Link to="/" className="btn btn-primary wh">
                    Read More
                  </Link>
                </div>
              </div>

              {/* end */}
            </div>
          </div>
        </div>
      </div>
      <section
        className="custom-section"
        style={{ backgroundColor: "#EBF4FA", color: "#000000" }}
      >
        <div className="container">
          <div className="row align-items-center">
            {/* Left Column */}
            <div className="col-md-8">
              <div className="left-content">
                <h1 className="mb-4">WANT TO PLACE AN ORDER ?</h1>
                <p className="mb-4">
                  If you wish to make a purchase, kindly provide additional
                  details regarding your order. We value your business and
                  eagerly anticipate assisting you with your purchase.
                </p>
                <Link
                  to="/contactuss"
                  className="btn btn-primary btn-get-started"
                >
                  Contact Us
                </Link>
              </div>
            </div>

            {/* Right Column */}
            <div className="col-md-4">
              <div className="right-content">
                <img
                  src={heroImage}
                  className="img-fluid animate__animated animate__fadeInLeft"
                  alt="Large"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Cards />

      {/* out portfolio start  */}

      {/* <div className="text-center mt-5">
        <h1 className="mb-4">Our Portfolio</h1>
      </div>
      <Project />

      <div className="App mb-4">
        <h1 className="py-4">Website Banners</h1>
        <WBannerslider />
      </div>

      <div className="Appportfolio">
        <h3>UI/UX DESIGN</h3>
        <div className="content-container">
          {UIUX.map((uiux, index) => (
            <div key={index} className="logo-item">
              <img src={uiux} alt={`Logo ${index + 1}`} />
            </div>
          ))}
        </div>
      </div>

      <div className="Appportfolio">
        <h3>SOCIAL MEDIA POST</h3>
        <div className="content-container">
          {SocialMedia.map((sm, index) => (
            <div key={index} className="logo-item">
              <img src={sm} alt={`Logo ${index + 1}`} />
            </div>
          ))}
        </div>
      </div> */}

      {/* out portfolio end */}

      <div
        className="App mb-0"
        style={{
          backgroundColor: "#e0f7fa",
          color: "#151B54",
          padding: "2em",
          borderRadius: "0px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.5)",
        }}
      >
        <div className="text-center mt-3">
          <h1
            className="mb-4"
            style={{
              color: "#333",
              fontSize: "3em",
              fontWeight: "bold",
              textShadow: "2px 2px 4px rgba(0, 0, 0, 0.6)",
            }}
          >
            Our Portfolio
          </h1>
        </div>
        <h1
          className="py-4"
          style={{ fontSize: "2.5em", fontWeight: "bold", textAlign: "center" }}
        >
          Website Banners
        </h1>
        <WBannerslider />
      </div>

      <div
        className="Appportfolio"
        style={{
          padding: "2em",
          backgroundColor: "#e0f7fa",
          borderRadius: "0px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
        }}
      >
        <h3
          style={{
            color: "#151B54",
            fontSize: "2em",
            fontWeight: "bold",
            marginBottom: "1em",
            textAlign: "center",
          }}
        >
          UI/UX DESIGN
        </h3>
        <div
          className="content-container"
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            gap: "1em",
          }}
        >
          {UIUX.map((uiux, index) => (
            <div
              key={index}
              className="logo-item"
              style={{
                width: "420px",
                height: "200px",
                overflow: "hidden",
                borderRadius: "15px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                transition: "transform 0.3s ease",
              }}
            >
              <img
                src={uiux}
                alt={`Logo ${index + 1}`}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "15px",
                  transition: "transform 0.3s ease",
                }}
              />
            </div>
          ))}
        </div>
      </div>

      <div
        className="Appportfolio"
        style={{
          padding: "2em",
          backgroundColor: "#e0f7fa",
          borderRadius: "0px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
        }}
      >
        <h3
          style={{
            color: "#151B54",
            fontSize: "2em",
            fontWeight: "bold",
            marginBottom: "1em",
            textAlign: "center",
          }}
        >
          SOCIAL MEDIA POST
        </h3>
        <div
          className="content-container"
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            gap: "1em",
          }}
        >
          {SocialMedia.map((sm, index) => (
            <div
              key={index}
              className="logo-item"
              style={{
                width: "350px",
                height: "350px",
                overflow: "hidden",
                borderRadius: "15px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                transition: "transform 0.3s ease",
              }}
            >
              <img
                src={sm}
                alt={`Logo ${index + 1}`}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "15px",
                  transition: "transform 0.3s ease",
                }}
              />
            </div>
          ))}
        </div>
      </div>

      {/* our team  */}

      {/* first pic start */}

      {/* <h1 className="text-center pt-0" style={{ marginTop: "2em" }}>
        Our Team
      </h1>
      <div className="card-main-wrap text-center">
        <div className="card-for-wid">
          <div className="card-img">
            <img src={osama} alt="" style={{ width: "340px" }} />
          </div>
          <div className="card-text-wrapper">
            <h2 className="heading-wrap">Osama Kalar</h2>
            <p className="paragraph-wrap">Founder of Techiocray</p>
          </div>
        </div>
        <button className="btnn" style={{}}>
          <Link to="/our-team" className="bttn" style={{}}>
            our team
          </Link>
        </button>
      </div>
      <div className="-cards-margi" style={{ marginTop: "3em" }}>
      </div> */}

      {/* first pic end */}

      {/* <div
        className="card-main-wrap text-center"
        style={{
          marginTop:"0",
          padding: "2em",
          backgroundColor: "#FFFFF4",
          boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <h1
          className="text-center pt-0"
          style={{
            marginTop: "1em",
            color: "#000",
            fontSize: "3em",
            fontWeight: "bold",
            paddingBottom: "1em",
          }}
        >
          Our Team
        </h1>
        <div
          className="card-for-wid"
          style={{
            maxWidth: "350px",
            backgroundColor: "#ffffff",
            borderRadius: "15px",
            boxShadow: "0 8px 16px rgba(0, 0, 0, 0.5)",
            padding: "10px",
            position: "relative",
          }}
        >
          <div className="card-img" style={{ marginBottom: "15px" }}>
            <img
              src={osama}
              alt=""
              style={{
                width: "100%",
                borderRadius: "5%",
                border: "4px solid #00796b",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              }}
            />
          </div>
          <div className="card-text-wrapper">
            <h2
              className="heading-wrap"
              style={{
                color: "#00796b",
                fontSize: "1.8em",
                margin: "10px 0",
                fontFamily: "Arial, sans-serif",
              }}
            >
              Osama Kalar
            </h2>
            <p
              className="paragraph-wrap"
              style={{
                color: "#004d40",
                fontSize: "1.2em",
                margin: "0",
                fontFamily: "Arial, sans-serif",
              }}
            >
              Founder of Techiocray
            </p>
          </div>
          <button
            className="btnn"
            style={{
              marginTop: "20px",
              backgroundColor: "#0909FF",
              border: "none",
              borderRadius: "25px",
              padding: "10px 25px",
              cursor: "pointer",
              boxShadow: "0 6px 12px rgba(0, 0, 0, 0.2)",
              transition: "background-color 0.3s ease",
            }}
          >
            <Link
              to="/our-team"
              className="bttn"
              style={{
                color: "#fff",
                textDecoration: "none",
                fontSize: "1.2em",
                fontWeight: "bold",
              }}
            >
              Our Team
            </Link>
          </button>
        </div>
      </div> */}
    </>
  );
}

export default App;
