import React from "react";
import "./paackages.css";

function paackages() {
  return (
    <>
      <div
        className="container-fluid d-flex justify-content-center align-items-center"
        style={{
          backgroundColor: "#007bff",
          color: "#fff",
          minHeight: "50vh",
        }}
      >
        <div className="text-center mt-5">
          <h1 className="mb-4">Packages</h1>
          <h5>Home | Our Packages</h5>
        </div>
      </div>

      <div className="packagecontainer">
        <div className="packageheading text-center py-4">
          <h1>Our Packages</h1>
          <p>
            At Techiocracy, we offer a wide array of digital services to meet
            your unique needs and propel your business or project to new
            heights. Our team of experts is dedicated <br /> to delivering
            exceptional results in each of the following service areas.
          </p>
        </div>
      </div>

      <div className="packages">
  <div className="package-card">
    <h2>Starter Web Suite</h2>
    <p>
      A complete web solution package including dynamic website design, SEO, E-commerce integration, and graphic design assets.
    </p>
  </div>
  <div className="package-card">
    <h2>Mobile Magic Solution</h2>
    <p>
      Comprehensive mobile app development with UI/UX design and a month of post-launch IT support and maintenance.
    </p>
  </div>
  <div className="package-card">
    <h2>AI Apprentice Suite</h2>
    <p>
      A suite of AI solutions including chatbots, machine learning, AI integration, and specialized AI for healthcare.
    </p>
  </div>
</div>
{/* second  */}
<div className="packages">
  <div className="package-card">
    <h2>Consultation and Training Bootcamp</h2>
    <p>
      Deep dive into IT consultancy and comprehensive training sessions for your team.
    </p>
  </div>
  <div className="package-card">
    <h2>Business Boost Solutions</h2>
    <p>
      Boost your business with digital marketing strategies, CMS, and E-commerce solutions.
    </p>
  </div>
  <div className="package-card">
    <h2>Retail Revolution Solution</h2>
    <p>
      Elevate your retail business with E-commerce AI enhancements and comprehensive Shopify solutions.
    </p>
  </div>
</div>


<div className="combo-packages">
  <h1>Combo Packages</h1>
  <div className="combo-container">
    <div className="combo-card">
      <h2>Digital Dynamo Package</h2>
      <p>
        Combine the Starter Web Suite and Business Boost Solutions for a complete digital business solution.
      </p>
    </div>
    <div className="combo-card">
      <h2>Tech Titan Package</h2>
      <p>
        Combine the Starter Web Suite and Business Boost Solutions for a complete digital business solution.
      </p>
    </div>
    <div className="combo-card">
      <h2>AI Innovator Package</h2>
      <p>
        Leverage AI in operations and sales with AI Apprentice Suite and Retail Revolution Solution.
      </p>
    </div>
    <div className="combo-card">
      <h2>Enterprise Expert Package</h2>
      <p>
        Comprehensive IT solutions and training with Data Dynamo Suite, Cloud Pioneer Framework, and Consultation and Training Bootcamp.
      </p>
    </div>
    <div className="combo-card">
      <h2>Complete Commerce Commander</h2>
      <p>
        Focus on E-commerce dominance with Starter Web Suite, Retail Revolution Solution, and Business Boost Solutions.
      </p>
    </div>
  </div>
</div>
    </>
  );
}

export default paackages;