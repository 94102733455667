import React from "react";
import { Link } from "react-router-dom";

function digitalMarketing() {
  return (
    <>
      <div
        className="container-fluid d-flex justify-content-center align-items-center"
        style={{ backgroundColor: "#007bff", color: "#fff", minHeight: "50vh" }}
      >
        <div className="text-center mt-5">
          <h1 className="mb-4">Digital Marketing</h1>
          <h5>Home | Digital Marketing</h5>
        </div>
      </div>

      <section className="py-5 has-eae-slider elementor-section elementor-top-section elementor-element elementor-section-boxed elementor-section-height-default elementor-section-height-default tf-sticky-section tf-sticky-no">
  <div className="container px-3 px-lg-5">
    <div className="row d-flex align-items-stretch">
      {/* row1 */}
      <div className="col-lg-6 animated fadeInLeft d-flex">
        <div className="elementor-widget-wrap flex-fill">
          <div className="elementor-widget elementor-widget-image">
            <a
              href="https://thehumantek.com/wp-content/uploads/2023/08/Digital-Marketing.png"
              data-elementor-open-lightbox="yes"
              data-elementor-lightbox-title="Digital Marketing"
              data-e-action-hash="#elementor-action%3Aaction%3Dlightbox%26settings%3DeyJpZCI6NzA4NSwidXJsIjoiaHR0cHM6XC9cL3RoZWh1bWFudGVrLmNvbVwvd3AtY29udGVudFwvdXBsb2Fkc1wvMjAyM1wvMDhcL0RpZ2l0YWwtTWFya2V0aW5nLnBuZyJ9"
            >
              <img
                className="elementor-animation-shrink img-fluid"
                src="https://thehumantek.com/wp-content/uploads/2023/08/Digital-Marketing.png"
                alt=""
                sizes="(max-width: 1080px) 100vw, 1080px"
              />
            </a>
          </div>
        </div>
      </div>

      {/* row2 */}
      <div className="col-lg-6 animated fadeInRight d-flex">
        <div className="elementor-widget-wrap flex-fill">
          <div className="elementor-widget elementor-widget-heading">
            <h2 className="elementor-heading-title">DIGITAL SOLUTION</h2>
          </div>
          <div className="elementor-widget elementor-widget-heading">
            <h2 className="elementor-heading-title">
              Welcome to Our Digital Marketing Services
            </h2>
          </div>
          <div className="elementor-widget elementor-widget-text-editor">
            <p>
              In today’s digital age, having a strong online presence is
              essential for any business to succeed.
            </p>
          </div>
          <div className="elementor-widget elementor-widget-text-editor">
            <p>
              And with the ever-increasing competition in the online
              world, it’s important to have a solid digital marketing
              strategy in place to ensure that your website stands out and
              attracts potential customers. Our digital marketing agency
              specializes in providing comprehensive and customized
              digital marketing solutions to help businesses succeed in
              the online world. With years of experience and a team of
              expert professionals, we offer a range of digital marketing
              services to help your business grow and achieve your goals.
            </p>
          </div>
          <div className="elementor-widget elementor-widget-button">
            <Link to="/contactuss" className="btn btn-primary">
              <i className="fas fa-angle-right"></i> Get Started
            </Link>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


      <section className="py-5 has-eae-slider elementor-section elementor-top-section elementor-element elementor-section-boxed elementor-section-height-default elementor-section-height-default tf-sticky-section tf-sticky-no">
        <div className="container px-3 px-lg-5">
          <div className="row">
            <div className="col-lg-6 animated fadeInLeft">
              <div className="elementor-widget-wrap">
                <div className="elementor-widget elementor-widget-image">
                  <a
                    href="https://thehumantek.com/wp-content/uploads/2023/08/social-media-marketing-1.png"
                    data-elementor-open-lightbox="yes"
                    data-elementor-lightbox-title="Social Media Marketing"
                    data-e-action-hash="#elementor-action%3Aaction%3Dlightbox%26settings%3DeyJpZCI6NzEyNiwidXJsIjoiaHR0cHM6XC9cL3RoZWh1bWFudGVrLmNvbVwvd3AtY29udGVudFwvdXBsb2Fkc1wvMjAyM1wvMDhcL3NvY2lhbC1tZWRpYS1tYXJrZXRpbmctMS5wbmcifQ%3D%3D"
                  >
                    <img
                      className="elementor-animation-shrink img-fluid"
                      src="https://thehumantek.com/wp-content/uploads/2023/08/social-media-marketing-1.png"
                      alt="Social Media Marketing"
                      title="Social Media Marketing"
                      loading="lazy"
                      sizes="(max-width: 1080px) 100vw, 1080px"
                      style={{ width: "80%", height: "80%" }} // Maintain aspect ratio
                    />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-6 animated fadeInRight">
              <div className="elementor-widget-wrap">
                <div className="elementor-widget elementor-widget-heading">
                  <h2 className="elementor-heading-title">SMM</h2>
                </div>
                <div className="elementor-widget elementor-widget-heading">
                  <h2 className="elementor-heading-title">
                    Social Media Marketing
                  </h2>
                </div>
                <div className="elementor-widget elementor-widget-text-editor">
                  <p>
                    Social media marketing is a powerful way to engage with your
                    target audience and build your brand. Our social media
                    marketing services include developing and executing social
                    media strategies across various platforms, creating and
                    sharing content, and monitoring and analyzing performance
                    metrics. We help you to build a community of engaged
                    followers and to leverage social media to drive more traffic
                    and sales to your website.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className=" py-0 has-eae-slider elementor-section elementor-top-section elementor-element elementor-section-boxed elementor-section-height-default elementor-section-height-default tf-sticky-section tf-sticky-no">
        <div className="container px-3 px-lg-5">
          <div className="row">
            <div className="col-lg-6 animated fadeInRight">
              <div className="elementor-widget-wrap">
                <div className="elementor-widget elementor-widget-heading">
                  <h2 className="elementor-heading-title elementor-size-default">
                    PPC
                  </h2>
                </div>
                <div className="elementor-widget elementor-widget-heading">
                  <h2 className="elementor-heading-title elementor-size-default">
                    Pay-Per-Click (PPC) Advertising
                  </h2>
                </div>
                <div className="elementor-widget elementor-widget-text-editor">
                  <p>
                    Our PPC advertising services allow you to reach your target
                    audience through paid ads on search engines and other
                    websites. We develop customized PPC campaigns that are
                    designed to attract potential.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 animated fadeInLeft">
              <div className="elementor-widget-wrap">
                <div className="elementor-widget elementor-widget-image">
                  <img
                    className="elementor-animation-shrink img-fluid"
                    src="https://thehumantek.com/wp-content/uploads/2023/08/ppc-1.png"
                    alt="PPC Advertising"
                    title="PPC Advertising"
                    loading="lazy"
                    style={{ width: "100%", height: "auto" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default digitalMarketing;
