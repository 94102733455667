import React from 'react';
import { Link } from 'react-router-dom';
// import emailIcon from '../images/comment-svg.png';
// import skypeIcon from '../images/comment-svg.png';
import { MdEmail } from "react-icons/md";
import { IoCallSharp } from "react-icons/io5";
import './ChatIconCall.css';

const ChatIcons = () => {
    return (
      <div className="chat-icons-wrapper">
        <div className="chat-icon-container email">
          <Link to="https://wa.me/message/ZNKOM6Y25T2XO1" className="chat-with-us-wrap d-flex">
            <div className="img-chat">
              {/* <img src={emailIcon} alt="Email Icon" /> */}
              <MdEmail/>
            </div>
            <div className="chat-us-heading">
              <h2 className="chat-us">Chat with Us</h2>
            </div>
          </Link>
        </div>
        <div className="chat-icon-container skype">
          <Link to="https://join.skype.com/DA6CCFYKA93L" className="chat-with-us-wrap d-flex">
            <div className="img-chat">
              {/* <img src={<FaSkype/>} alt="Skype Icon" /> */}
              <IoCallSharp/>
            </div>
            <div className="chat-us-heading">
              <h2 className="chat-us">Call Us</h2>
            </div>
          </Link>
        </div>
      </div>
    );
  };
  
  export default ChatIcons;