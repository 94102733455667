import React from 'react';
import neurasphere from '../images/neurasphere.png';
import secondwebiste from '../images/secondwebsite.png';
import airporttaxi from '../images/previousproject.png'
import './ourPreviousProjects.css';

const projects = [
  { id: 1, url: 'https://neurasphere.net/', image: neurasphere, title: 'Neurasphere' },
  { id: 2, url: 'https://bejewelled-truffle-d86715.netlify.app/', image: secondwebiste, title: 'DIGITAL NEXUS' },
  { id: 3, url: 'hhttps://airporttaxitxs.com/', image: airporttaxi , title: 'Houston Airport Transport' },
];

const PreviousProjects = () => {
  return (
    <div className="projects-container">
      <h2>Our Previous Projects</h2>
      <div className="projects-grid">
        {projects.map((project) => (
          <div key={project.id} className="project-card">
            <a href={project.url} target="_blank" rel="noopener noreferrer">
              <img src={project.image} alt={project.title} className="project-image" />
              <div className="project-title">{project.title}</div>
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PreviousProjects;