import React from "react";
import './softwaredev.css'

function softwaredev() {
  return (
    <>
      <div
        className="container-fluid d-flex justify-content-center align-items-center"
        style={{ backgroundColor: "#007bff", color: "#fff", minHeight: "50vh" }}
      >
        <div className="text-center mt-5">
          <h1 className="mb-4">Software Development</h1>
          <h5>Home | Software Development</h5>
        </div>
      </div>

      <div className="container-fluid bg-white text-dark pb-5 py-4 font-bootstrap">
        <div className="row justify-content-center">
          <div className="col-md-8 text-center">
            <p className="mb-5">
              Techiocracy offers top notch software development services, creating
              customized solutions that efficient processes, improve <br />
              productivity, and drive advancement for your business’ digital
              presence.
            </p>
            <p>
              As a credible software development services provider, we offer
              customized solutions to turn your briefs into operative <br />
              applications. From idea to coding, testing to sending, we
              guarantee consistent progress conveying productive and proficient <br />
              software that meets your business needs.
            </p>
          </div>
        </div>
      </div>

      <section className="services-section">
      <div className="containersoftdev">
        <div className="heading">
          <h2>SERVICES</h2>
        </div>
        
        <div className="services-container">
          <div className="service-item">
            <a href="/" className="service-link">
              <div className="service-box">
                <h3 className="service-title">Front end Development</h3>
                <p className="service-description">
                  Our professional software developers provide extraordinary user experiences with our front-end development services. We create pixel-perfect, responsive, and intuitive points of interaction that bewitch visitors from an initiative click. Evolve your online availability with our expert skills in HTML, CSS, JavaScript, and frameworks, ensuring smooth functionality across all devices.
                </p>
              </div>
            </a>
          </div>
          
          <div className="service-item">
            <a href="/" className="service-link">
              <div className="service-box">
                <h3 className="service-title">Back end Development</h3>
                <p className="service-description">
                  Our back-end development services cover the digital base for smooth operations. We develop strong server frameworks, database operations, and APIs that delegate your applications. With accurate coding and optimization, we promise secure, proficient, and adaptable solutions that steer your business towards success. Let us evolve your concepts into functional Applications.
                </p>
              </div>
            </a>
          </div>
          
          <div className="service-item">
            <a href="/" className="service-link">
              <div className="service-box">
                <h3 className="service-title">Full Stack Development</h3>
                <p className="service-description">
                  Collaborate with us to get detailed and productive digital solutions with our top-notch full stack development services. Flawlessly combining front-end and back-end skill, we create dynamic, customer oriented applications. From responsive connection points to powerful server systems, we take care of you. Raise your digital presence with our comprehensive way to deal with current web development.
                </p>
              </div>
            </a>
          </div>
        </div>
        
        <div className="services-container">
          <div className="service-item">
            <a href="/" className="service-link">
              <div className="service-box">
                <h3 className="service-title">Application Development</h3>
                <p className="service-description">
                  We are using the latest tools and techniques to offer professional Application Development to facilitate our clients in converting their ideas into existence. With an emphasis on development and client oriented plan, we make customized applications that consistently correlate practicality and innovation. Elevate your business with our cutting-edge solutions that cater to your unique needs and drive effective engagement.
                </p>
              </div>
            </a>
          </div>
          
          <div className="service-item">
            <a href="/" className="service-link">
              <div className="service-box">
                <h3 className="service-title">API Development</h3>
                <p className="service-description">
                  Unfold consistent correspondence between applications with our remarkable API Development services. Our skillful teams create strong APIs that guarantee smooth data channeling, upgraded functionality, and smoothed out processes. Raise your business with customized solutions that work with ongoing cooperation and head towards advancement. Experience the implementation of productive API Development with us.
                </p>
              </div>
            </a>
          </div>
          
          <div className="service-item">
            <a href="/" className="service-link">
              <div className="service-box">
                <h3 className="service-title">Mobile Development</h3>
                <p className="service-description">
                  Along the successful journey and experiences in Mobile Development services, we create dynamic and client driven applications that enamor crowds in a hurry. We work in making consistent encounters across iOS and Android platforms, engaging trailblazing technologies to guarantee optimal execution and functionality. Lift your business with our team specialists, devoted to creating an interpretation of your ideas into productive and innovative mobile solutions.
                </p>
              </div>
            </a>
          </div>
        </div>
      </div>
    </section>
    </>
  );
}

export default softwaredev;