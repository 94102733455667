import React from 'react'
import './ai.css'
import aiimg from '../images/ai-image.png'

import { Link } from 'react-router-dom'
function artificialintellegence() {
  return (
    <>
    <div
        className="container-fluid d-flex justify-content-center align-items-center"
        style={{
          backgroundColor: "#007bff",
          color: "#fff",
          minHeight: "50vh",
        }}
      >
        <div className="text-center mt-5">
          <h1 className="mb-4">
          AI and Machine Learning</h1>
          <h5>Home | Artificial Intelligence</h5>
        </div>
      </div>

      <div className="aicontainer">
      <div className="aitext-container">
      <div className="row align-items-center">
      <div className="col-md-6 mb-4">
      <h1 className="aiheading">Innovate with <br /> AI and Machine Learning</h1>
        <p className='ai-wid'>
          Techiocracy offers cutting-edge AI and machine learning solutions designed to help you harness the power of artificial intelligence to drive innovation and efficiency. Our services cover everything from developing AI-powered features to integrating machine learning models into your existing systems.
        </p>
      </div>
      <div className="col-md-6 mb-4">
      <div className="aiimage-container">
        <img
          src={aiimg}
          srcSet="https://letsdreambeyond.com/wp-content/uploads/2024/07/Robotics-pana-300x188.jpg 300w, https://letsdreambeyond.com/wp-content/uploads/2024/07/Robotics-pana-1024x641.jpg 1024w, https://letsdreambeyond.com/wp-content/uploads/2024/07/Robotics-pana-200x125.jpg 200w, https://letsdreambeyond.com/wp-content/uploads/2024/07/Robotics-pana-600x375.jpg 600w, https://letsdreambeyond.com/wp-content/uploads/2024/07/Robotics-pana.jpg 1285w"
          alt="Robotics"
          className="airesponsive-image"
        />
      </div>
      </div>
      </div>
      
      </div>
    </div>

    <div className="aisubheading-container">
        <h2 className="aisubheading">
          Why Choose Our<br />
          <span className="aisubheading-highlight">AI and Machine Learning Solutions?</span>
        </h2>
      </div>

      <div className="services-container">
      <div className="services-inner">
        <div className="service-item">
          <h2 className="service-heading">Custom AI Development</h2>
          <p>
            We develop custom AI solutions tailored to your specific business needs. Whether you need AI-powered automation, predictive analytics, or natural language processing, we have the <span className="highlight">expertise</span> to deliver innovative solutions.
          </p>
        </div>
        <div className="service-item">
          <h2 className="service-heading">Machine Learning Model Development</h2>
          <p>
            Our team creates and trains machine learning models that can be integrated into your business processes. These models help you make data-driven decisions, automate tasks, and improve operational efficiency.
          </p>
        </div>
      </div>
    </div>

    <div className="features-container">
      <div className="features-inner">
        <div className="feature-item">
          <h2 className="feature-heading">Seamless Integration</h2>
          <p>
            We ensure that our AI and machine learning solutions integrate seamlessly with your existing systems and workflows, providing a smooth transition and maximum benefit.
          </p>
        </div>
        <div className="feature-item">
          <h2 className="feature-heading">Continuous Support and Improvement</h2>
          <p>
            AI and machine learning are constantly evolving fields. We provide ongoing support and updates to ensure your solutions <span className="highlight">remain</span> at the forefront of technology and continue to deliver value.
          </p>
        </div>
      </div>
    </div>

    {/* key benifits  */}

    <div className="key-benefits-container">
      <h2 className="key-benefits-heading">
       <strong>Key Benefits of AI and Machine Learning <br /> Solutions with</strong> 
        <span className="highlight"> Techiocracy </span>
      </h2>
    </div>

    <section className="key-benefits-section">
      <div className="containerr">
        <div className="row">
          <div className="column">
            <div className="faq-item">
              <div className="faq-header">
                <h2 className="faq-title">Enhanced Efficiency</h2>
              </div>
              <div className="faq-body">
                Automate routine tasks and optimize processes with AI-powered solutions, freeing up your team to focus on higher-value activities.
              </div>
            </div>
          </div>
          <div className="column">
            <div className="faq-item">
              <div className="faq-header">
                <h2 className="faq-title">Data-Driven Insights</h2>
              </div>
              <div className="faq-body">
                Leverage machine learning to gain deeper insights from your data, helping you make more informed and strategic decisions.
              </div>
            </div>
          </div>
          <div className="column">
            <div className="faq-item">
              <div className="faq-header">
                <h2 className="faq-title">Competitive Advantage</h2>
              </div>
              <div className="faq-body">
                Stay ahead of the competition by adopting innovative AI technologies that improve your products, services, and operations.
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    {/* Transform Your Business  */}

    <section className="your-section">
      <div className="containerr">
        <div className="text-column">
          <h2 className="title">Transform Your Business with AI Today</h2>
          <p className="description">
            Embrace the future of technology with Dream Beyond’s AI and machine learning solutions. Contact us today to discuss your needs and discover how we can help you innovate and drive efficiency with artificial intelligence.
          </p>
        </div>
        <div className="button-column">
          <Link to="/contactuss" className="contact-button">
            Contact us now!<i className="fas fa-arrow-right"></i>
          </Link>
        </div>
      </div>
    </section>

    <div className="containerr">
      <div className="card">
        <h2>Machine Learning</h2>
        <p>
          The professionals in our organization engage and make permits for your firm with machine learning innovation that can distinguish models, perceive points and comprehend complex and complicated information.
        </p>
      </div>
      <div className="card">
        <h2>Natural Language Processing</h2>
        <p>
          We are absolute professionals in Natural Language Processing augmentation which lets the gadget to figure and incorporate what server write and speak, decipher the association and suppositions and take appropriate tasks.
        </p>
      </div>
      <div className="card">
        <h2>Chatbot</h2>
        <p>
          It is an as of late joined stream to remain nearer to customers. We prescribe organizations in creating Chatbot that advantages in connection to the page and portable application in space to show the exceptional user experience (UX).
        </p>
      </div>
    </div>

    </>
  )
}

export default artificialintellegence