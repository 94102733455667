import './App.css';

import { createBrowserRouter, RouterProvider, Outlet } from 'react-router-dom';

import Header from './components/header';
import PageFooter from './components/footer'
import HomePage from './components/home'
import Portfolio from './components/portfolio'
import Mobileapp from './components/mobileapp'
import Scrolltop from './components/scrolltop'
import WebDev from './components/web'
import Animation from './components/animation'
import Graphics from './components/graphicDesign'
import DigitalMarketing from './components/digitalMarketing'
import SEO from './components/searchengineoptimization'
import Softwaredev from './components/softwaredevlopment'
// import Ourteam from './components/our-team'
import Ourcompany from './components/our-company'
import Artificial from './components/artificial'
import Packages from './components/packages'
import Pack from './components/onlinebusiness'
import Ecommercebusiness from './components/ecommercebusiness'
import Contactus from './components/contactus'
// Layout component to include Header and Footer
const Layout = () => (
  <>
    <Header />
    <Scrolltop />
    <main>
      <Outlet />
    </main>
    <PageFooter />
  </>
);

const router = createBrowserRouter([
  {
    element: <Layout />,
    children: [
      { path: "/", element: <HomePage /> },
      { path: "/portfolio", element: <Portfolio /> },
      { path: "/mobile-app-development", element: <Mobileapp /> },
      { path: "/web-development", element: <WebDev /> },
      { path: "/animation", element: <Animation /> },
      { path: "/graphic-designing", element: <Graphics /> },
      { path: "/digital-marketing", element: <DigitalMarketing /> },
      { path: "/seo", element: <SEO /> },
      { path: "/software-development", element: <Softwaredev /> },
      { path: "/our-company", element: <Ourcompany /> },
      { path: "/ai", element: <Artificial /> },
      { path: "/packages", element: <Packages /> },
      { path: "/online-b", element: <Pack /> },
      { path: "/ecommerce", element: <Ecommercebusiness /> },
      { path: "/contactuss", element: <Contactus /> }, // Ensure this matches your intended path
    ],
  },
]);


function App() {
  return (
    <RouterProvider router={router} />
  );
}

export default App;