import React from "react";
import { Link } from "react-router-dom";

function web() {
  return (
    <>
      <div
        className="container-fluid d-flex justify-content-center align-items-center"
        style={{ backgroundColor: "#007bff", color: "#fff", minHeight: "50vh" }}
      >
        <div className="text-center mt-5">
          <h1 className="mb-4">Web Development</h1>
          <h5>Home | Web Development</h5>
        </div>
      </div>

      <div className="container-fluid bg-white text-dark py-5 font-bootstrap">
        <div className="row justify-content-center">
          <div className="col-md-8 text-center">
            <p className="mb-4">
            Techiocracy is keen to develop innovative web for your business. We
              offer a range of web development services in Pakistan.
            </p>
            <p>
              Our web developers assist brands in managing the dynamic digital
              world. We offer first-rate web design services to enhance brand
              recognition by making custom websites that allow you to set up
              your website however you like.
            </p>
            <p>
            Techiocracy amalgamates inventiveness and imagination to design 100%
              adaptable and interactive web solutions.
            </p>

            <h1 className="py-4">Services</h1>
            {/* <hr className="my-5 hr-under-lead" /> */}
          </div>
        </div>
      </div>

      <div className="container py-4">
        <div className="row align-items-center">
          {/* Right Column */}
          <div className="col-lg-6 animated fadeInRight">
            <div className="elementor-widget-wrap elementor-element-populated">
              <div className="elementor-element elementor-element-066b8f8 elementor-widget elementor-widget-heading">
                <div className="elementor-widget-container">
                  <h2 className="elementor-heading-title elementor-size-default">
                    Unique Website
                  </h2>
                </div>
              </div>
              <div className="elementor-element elementor-element-91e7028 elementor-widget elementor-widget-heading">
                <div className="elementor-widget-container">
                  <h2 className="elementor-heading-title elementor-size-default">
                    Custom-Web Development
                  </h2>
                </div>
              </div>
              <div className="elementor-element elementor-element-efd2672 elementor-widget__width-auto elementor-widget elementor-widget-text-editor">
                <div className="elementor-widget-container">
                  <p>
                    To connect our clients with their customers effectively, we
                    research, plan, design, and implement custom web solutions.
                    With an engaging UI/UX design that strengthens your brand,
                    we create feature-rich, highly functional, durable, secure,
                    and scalable custom web solutions. We are experts in
                    developing custom websites of any complexity to meet the
                    demands of your company’s operations.
                  </p>
                </div>
              </div>
              <div className="elementor-element elementor-element-ec92152 elementor-align-left elementor-mobile-align-center elementor-widget elementor-widget-button">
                <div className="elementor-widget-container">
                  <div className="elementor-button-wrapper">
                    <Link
                      className="elementor-button elementor-button-link elementor-size-sm elementor-animation-shrink"
                      to="/contactuss"
                      style={{
                        borderRadius: '8px',
                        padding: '0.5em 1em',
                        backgroundColor: '#007bff',
                        color: 'white',
                        border: 'none',
                      }}
                    >
                      <span className="elementor-button-content-wrapper">
                        <span className="elementor-button-icon elementor-align-icon-right">
                          <i
                            className="fas fa-angle-right"
                            aria-hidden="true"
                          ></i>
                        </span>
                        <span className="elementor-button-text">
                          Get Started
                        </span>
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Left Column */}
          <div className="col-lg-6 animated fadeInLeft">
            <div className="elementor-widget-wrap elementor-element-populated">
              <div className="elementor-element elementor-element-3356d13 elementor-widget elementor-widget-image">
                <div className="elementor-widget-container">
                  <img
                    src="https://thehumantek.com/wp-content/uploads/2023/08/Custom-Web-Development.png"
                    title="Custom-Web Development"
                    alt="Custom-Web Development"
                    loading="lazy"
                    width="300"
                    height="300"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container py-4">
        <div className="row align-items-center">
          {/* Left Column */}
          <div className="col-lg-6 animated fadeInLeft">
            <div className="elementor-widget-wrap elementor-element-populated">
              <div className="elementor-element elementor-element-cc23ed1 elementor-widget elementor-widget-image">
                <div className="elementor-widget-container">
                  <a
                    href="https://thehumantek.com/wp-content/uploads/2023/08/Responsive-Website-Design-1.png"
                    data-elementor-open-lightbox="yes"
                    data-elementor-lightbox-title="Responsive Website Design"
                  >
                    <img
                      fetchpriority="high"
                      decoding="async"
                      width="300"
                      height="300"
                      src="https://thehumantek.com/wp-content/uploads/2023/08/Responsive-Website-Design-1.png"
                      className="attachment-full size-full wp-image-7229"
                      alt=""
                      srcSet="https://thehumantek.com/wp-content/uploads/2023/08/Responsive-Website-Design-1.png 1080w, https://thehumantek.com/wp-content/uploads/2023/08/Responsive-Website-Design-1-300x300.png 300w, https://thehumantek.com/wp-content/uploads/2023/08/Responsive-Website-Design-1-1024x1024.png 1024w, https://thehumantek.com/wp-content/uploads/2023/08/Responsive-Website-Design-1-150x150.png 150w, https://thehumantek.com/wp-content/uploads/2023/08/Responsive-Website-Design-1-768x768.png 768w"
                      sizes="(max-width: 1080px) 100vw, 1080px"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>

          {/* Right Column */}
          <div className="col-lg-6 animated fadeInRight">
            <div className="elementor-widget-wrap elementor-element-populated">
              <div className="elementor-element elementor-element-d5949f1 elementor-widget elementor-widget-heading">
                <div className="elementor-widget-container">
                  <h2 className="elementor-heading-title elementor-size-default">
                    Ensure Good Visibility
                  </h2>
                </div>
              </div>
              <div className="elementor-element elementor-element-6c71a45 elementor-widget elementor-widget-heading">
                <div className="elementor-widget-container">
                  <h2 className="elementor-heading-title elementor-size-default">
                    Responsive Website Design
                  </h2>
                </div>
              </div>
              <div className="elementor-element elementor-element-cc9c7ce elementor-widget__width-auto elementor-widget elementor-widget-text-editor">
                <div className="elementor-widget-container">
                  <p>
                    With responsive design, the website content can adapt to any
                    screen size or quality and appear fantastic on any device.
                    Techiocracy converts the functionality of your web solution
                    into an intuitive, smoothly running, and highly converting
                    digital experience by employing the mobile-first strategy.
                    You can avoid spending time and money on creating and
                    developing the user interfaces for every new device that
                    enters the market by using responsive website design.
                  </p>
                </div>
              </div>
              <div className="elementor-element elementor-element-4fbf19d elementor-align-left elementor-mobile-align-center elementor-widget elementor-widget-button">
                <div className="elementor-widget-container">
                  <div className="elementor-button-wrapper">
                    <Link
                      className="elementor-button elementor-button-link elementor-size-sm elementor-animation-shrink"
                      to="/contactuss"
                      style={{
                        borderRadius: '8px',
                        padding: '0.5em 1em',
                        backgroundColor: '#007bff',
                        color: 'white',
                        border: 'none',
                      }}
                    >
                      <span className="elementor-button-content-wrapper">
                        <span className="elementor-button-icon elementor-align-icon-right">
                          <i
                            aria-hidden="true"
                            className="fas fa-angle-right"
                          ></i>
                        </span>
                        <span className="elementor-button-text">
                          Get Started
                        </span>
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container py-4">
        <div className="row align-items-center">
          {/* Left Column */}
          <div className="col-lg-6 animated fadeInLeft">
            <div className="elementor-widget-wrap elementor-element-populated">
              <div className="elementor-element elementor-element-5450b81 elementor-widget elementor-widget-heading">
                <div className="elementor-widget-container">
                  <h2 className="elementor-heading-title elementor-size-default">
                    Easy Modifications
                  </h2>
                </div>
              </div>
              <div className="elementor-element elementor-element-f4146d9 elementor-widget elementor-widget-heading">
                <div className="elementor-widget-container">
                  <h2 className="elementor-heading-title elementor-size-default">
                    CMS based Web Development
                  </h2>
                </div>
              </div>
              <div className="elementor-element elementor-element-5f86c7f elementor-widget__width-auto elementor-widget elementor-widget-text-editor">
                <div className="elementor-widget-container">
                  <p>
                    A CMS system provides end customers with a perfect
                    experience that fully utilizes the power of modern
                    technologies. We provide CMS web development services to
                    expand your business reach. We ensure the CMS you select
                    helps handle online operations by analyzing your business
                    model and conducting in-depth market research. We offer CMS
                    development services for all sizes of businesses. As an
                    innovative CMS development company, we can create amazing
                    layouts for the CMS of your choice. We can create unique
                    templates for the firm’s branding, logo, color scheme, and
                    preferred content layout.
                  </p>
                </div>
              </div>
              <div className="elementor-element elementor-element-4be387c elementor-align-left elementor-mobile-align-center elementor-widget elementor-widget-button">
                <div className="elementor-widget-container">
                  <div className="elementor-button-wrapper">
                    <Link
                      className="elementor-button elementor-button-link elementor-size-sm elementor-animation-shrink"
                      to="/contactuss"
                      style={{
                        borderRadius: '8px',
                        padding: '0.5em 1em',
                        backgroundColor: '#007bff',
                        color: 'white',
                        border: 'none',
                      }}
                    >
                      <span className="elementor-button-content-wrapper">
                        <span className="elementor-button-icon elementor-align-icon-right">
                          <i
                            aria-hidden="true"
                            className="fas fa-angle-right"
                          ></i>
                        </span>
                        <span className="elementor-button-text">
                          Get Started
                        </span>
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Right Column */}
          <div className="col-lg-6 animated fadeInRight">
            <div className="elementor-widget-wrap elementor-element-populated">
              <div className="elementor-element elementor-element-b68fa0e elementor-widget elementor-widget-image">
                <div className="elementor-widget-container">
                  <img
                    decoding="async"
                    src="https://thehumantek.com/wp-content/uploads/2023/08/CMS-based-Web-Development.png"
                    title="CMS based Web Development"
                    alt="CMS based Web Development"
                    loading="lazy"
                    width="300"
                    height="300"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container py-4 mb-5">
        <div className="row align-items-center">
          {/* Left Column */}
          <div className="col-lg-6 animated fadeInLeft">
            <div className="elementor-widget-wrap elementor-element-populated">
              <div className="elementor-element elementor-element-4becd82 elementor-widget elementor-widget-image">
                <div className="elementor-widget-container">
                  <img
                    decoding="async"
                    src="https://thehumantek.com/wp-content/uploads/2023/08/E-Commerce-Website-Development.png"
                    title="E-Commerce Website Development"
                    alt="E-Commerce Website Development"
                    loading="lazy"
                    width="300"
                    height="300"
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Right Column */}
          <div className="col-lg-6 animated fadeInRight">
            <div className="elementor-widget-wrap elementor-element-populated">
              <div className="elementor-element elementor-element-da295f7 elementor-widget elementor-widget-heading">
                <div className="elementor-widget-container">
                  <h2 className="elementor-heading-title elementor-size-default">
                    Insert Clear CTAs
                  </h2>
                </div>
              </div>
              <div className="elementor-element elementor-element-7edbb91 elementor-widget elementor-widget-heading">
                <div className="elementor-widget-container">
                  <h2 className="elementor-heading-title elementor-size-default">
                    E-Commerce Website Development
                  </h2>
                </div>
              </div>
              <div className="elementor-element elementor-element-543e29e elementor-widget__width-auto elementor-widget elementor-widget-text-editor">
                <div className="elementor-widget-container">
                  <p>
                    We assist you in standing out distinctively online.
                    E-Commerce websites have fundamentally transformed how
                    people buy and sell products, altering how businesses
                    operate. Techiocracy provides the expertise and solutions to
                    help you reach your objectives on a big scale if you are a
                    big corporation wishing to create a unique, well-branded
                    E-Commerce website. We push the boundaries of bespoke
                    E-Commerce website design to make new ideas come to life
                    with complex functionality. To assist keep your site up for
                    business and able to make money all the time, we offer 24×7
                    support and maintenance.
                  </p>
                </div>
              </div>
              <div className="elementor-element elementor-element-4e0076c elementor-align-left elementor-mobile-align-center elementor-widget elementor-widget-button">
                <div className="elementor-widget-container">
                  <div className="elementor-button-wrapper">
                    <Link
                      className="elementor-button elementor-button-link elementor-size-sm elementor-animation-shrink"
                      to="/contactuss"
                      style={{
                        borderRadius: '8px',
                        padding: '0.5em 1em',
                        backgroundColor: '#007bff',
                        color: 'white',
                        border: 'none',
                      }}
                    >
                      <span className="elementor-button-content-wrapper">
                        <span className="elementor-button-icon elementor-align-icon-right">
                          <i
                            aria-hidden="true"
                            className="fas fa-angle-right"
                          ></i>
                        </span>
                        <span className="elementor-button-text">
                          Get Started
                        </span>
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container py-4 mb-5">
        <div className="row align-items-center">
          {/* Left Column */}
          <div className="col-lg-6 animated fadeInRight">
            <div className="elementor-widget-wrap elementor-element-populated">
              <div className="elementor-element elementor-element-9bb1c0d elementor-widget elementor-widget-heading">
                <div className="elementor-widget-container">
                  <h2 className="elementor-heading-title elementor-size-default">
                    Enhanced Development
                  </h2>
                </div>
              </div>
              <div className="elementor-element elementor-element-84abcaf elementor-widget elementor-widget-heading">
                <div className="elementor-widget-container">
                  <h2 className="elementor-heading-title elementor-size-default">
                    Progressive Web Development
                  </h2>
                </div>
              </div>
              <div className="elementor-element elementor-element-5b58388 elementor-widget__width-auto elementor-widget elementor-widget-text-editor">
                <div className="elementor-widget-container">
                  <p>
                    We employ progressive enhancement techniques to develop
                    cross-platform web applications. Our goal is to create web
                    apps that precisely match your brand and are quick,
                    dependable, and appealing. We work with skilled web
                    developers to create responsive Progressive Web Applications
                    (PWAs) that deliver excellent performance in both online and
                    offline modes.
                  </p>
                </div>
              </div>
              <div className="elementor-element elementor-element-6ceb576 elementor-align-left elementor-mobile-align-center elementor-widget elementor-widget-button">
                <div className="elementor-widget-container">
                  <div className="elementor-button-wrapper">
                    <Link
                      className="elementor-button elementor-button-link elementor-size-sm elementor-animation-shrink"
                      to="/contactuss"
                      style={{
                        borderRadius: '8px',
                        padding: '0.5em 1em',
                        backgroundColor: '#007bff',
                        color: 'white',
                        border: 'none',
                      }}
                    >
                      <span className="elementor-button-content-wrapper">
                        <span className="elementor-button-icon elementor-align-icon-right">
                          <i
                            aria-hidden="true"
                            className="fas fa-angle-right"
                          ></i>
                        </span>
                        <span className="elementor-button-text">
                          Get Started
                        </span>
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Right Column */}
          <div className="col-lg-6 animated fadeInLeft">
            <div className="elementor-widget-wrap elementor-element-populated">
              <div className="elementor-element elementor-element-f63f376 elementor-widget elementor-widget-image">
                <div className="elementor-widget-container">
                  <img
                    decoding="async"
                    src="https://thehumantek.com/wp-content/uploads/2023/08/Progressive-Web-Development.png"
                    title="Progressive Web Development"
                    alt="Progressive Web Development"
                    loading="lazy"
                    width="300"
                    height="300"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default web;
