import React from "react";

function services() {
  return (
    <>
      <div
        className="container-fluid d-flex justify-content-center align-items-center"
        style={{ backgroundColor: "#007bff", color: "#fff", minHeight: "50vh" }} >
        <div className="text-center mt-5">
          <h1 className="mb-4">2D & 3D Animation</h1>
          <h5>Home | Animation</h5>
        </div>
      </div>

      <div className="container-fluid bg-white text-dark py-5 font-bootstrap">
        <div className="row justify-content-center">
          <div className="col-md-8 text-center">
            <p className="mb-5">
            Techiocracy provides exceptional video animation services in Pakistan
              that demonstrate shades of professionalism, creativity, and
              uniqueness. With exceptional video animation services, we layout
              your brand, animate and deliver a clear and impactful message to
              the right target audience.
            </p>
            <p>
              Our team collaborates with clients to achieve the best possible
              results for animated videos. If you're looking for creative
              animation services that will blow your customers away, you've come
              to the right place.
            </p>

            <p className="lead py-4">Have a look at our services</p>
            <hr className="my-5 hr-under-lead" />
          </div>
        </div>

        <div className="container text-center py-4">
      <h1>Our Process of Animation</h1>
    </div>

        <div className="container py-5">
          <div className="row justify-content-center">
            <div className="col-md-4 col-lg-3 text-center mb-4 px-3 mx-auto">
              <a href="/" className="text-dark d-block link-no-decoration">
                <i className="fas fa-chart-bar fa-3x mb-3"></i>
                <h3 className="h4">The Creative Brief</h3>
              </a>
              <p>
                Brief us on your story! Then let us decide on the animation
                type, video length, and overall look and feel of your animated
                video. We strictly follow the requirements provided by our
                clients.
              </p>
            </div>

            <div className="col-md-4 col-lg-3 text-center mb-4 px-3 mx-auto">
              <a href="/" className="text-dark d-block link-no-decoration">
                <i className="fas fa-cog fa-3x mb-3"></i>
                <h3 className="h4">Storyboarding</h3>
              </a>
              <p>
                Following that, we will create a series of illustrations
                outlining the sequence of your story to assist you in
                envisioning how your final animated video will look.
              </p>
            </div>

            <div className="col-md-4 col-lg-3 text-center mb-4 px-3 mx-auto">
              <a href="/" className="text-dark d-block link-no-decoration">
                <i className="fas fa-laptop-code fa-3x mb-3"></i>
                <h3 className="h4">Creation and Finalizing</h3>
              </a>
              <p>
                We begin the animation process after you have approved the
                storyboard. The final version of your project will be released
                once you have approved all the details.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container text-center py-4">
      <h1>TYPES OF ANIMATIONS</h1>
    </div>

    <div className="container py-4">
      <div className="row justify-content-center align-items-center">
        {/* Column for Image */}
        <div className="col-md-6 animated fadeInLeft pl-md-4">
          <div className="elementor-widget-wrap">
            <div className="elementor-widget elementor-widget-image">
              <div className="elementor-widget-container">
                <a
                  href="https://thehumantek.com/wp-content/uploads/2023/03/abd7a42750a2268fbd1088994e623ade.gif"
                  data-elementor-open-lightbox="yes"
                  data-elementor-lightbox-title="abd7a42750a2268fbd1088994e623ade"
                  data-e-action-hash="#elementor-action%3Aaction%3Dlightbox%26settings%3DeyJpZCI6MzIwNiwidXJsIjoiaHR0cHM6XC9cL3RoZWh1bWFudGVrLmNvbVwvd3AtY29udGVudFwvdXBsb2Fkc1wvMjAyM1wvMDNcL2FiZDdhNDI3NTBhMjI2OGZiZDEwODg5OTRlNjIzYWRlLmdpZiJ9"
                >
                  <img
                    src="https://thehumantek.com/wp-content/uploads/2023/03/abd7a42750a2268fbd1088994e623ade.gif"
                    alt="abd7a42750a2268fbd1088994e623ade"
                    className="elementor-animation-shrink img-fluid"
                    loading="lazy"
                    style={{ maxWidth: '50%', height: 'auto' }}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>

        {/* Column for Content */}
        <div className="col-md-6 animated fadeInLeft pr-md-4">
          <div className="elementor-widget-wrap">
            <div className="elementor-widget elementor-widget-heading">
              <div className="elementor-widget-container">
                <h2 className="elementor-heading-title">Smooth Movements</h2>
              </div>
            </div>
            <div className="elementor-widget elementor-widget-heading">
              <div className="elementor-widget-container">
                <h2 className="elementor-heading-title">2D Animation</h2>
              </div>
            </div>
            <div className="elementor-widget elementor-widget-text-editor">
              <div className="elementor-widget-container">
                <p>
                Techiocracy 2D animation experts are incredibly creative,
                  innovative, and demanding thinkers. They understand what it
                  takes to create a compelling and engaging 2D animation video,
                  so they leverage their professional experience to create
                  excellent videos for your brand.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div className="container py-4">
      <div className="row justify-content-center">
        {/* Column for Text Content */}
        <div className="col-md-6 animated fadeInRight pr-md-4">
          <div className="elementor-widget-wrap">
            <div className="elementor-element elementor-element-e2eaed4 elementor-widget elementor-widget-heading">
              <div className="elementor-widget-container">
                <h2 className="elementor-heading-title elementor-size-default">Vivid Characters</h2>
              </div>
            </div>
            <div className="elementor-element elementor-element-7364e9b elementor-widget elementor-widget-heading">
              <div className="elementor-widget-container">
                <h2 className="elementor-heading-title elementor-size-default">3D Animation</h2>
              </div>
            </div>
            <div className="elementor-element elementor-element-e43e726 elementor-widget__width-auto elementor-widget elementor-widget-text-editor">
              <div className="elementor-widget-container">
                <p>
                Techiocracy utilizes 3D graphics and gives you an exciting experience of 3D animation.
                  3D animation relies on the creative input and careful work of animators and designers.
                  Our 3D animation services will bring an utter change to your business look and make
                  it more appealing to your audience.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Column for Image */}
        <div className="col-md-6 animated fadeInLeft pl-md-4">
          <div className="elementor-widget-wrap">
            <div className="elementor-element elementor-element-8d04dcc elementor-widget elementor-widget-image">
              <div className="elementor-widget-container">
                <a
                  href="https://thehumantek.com/wp-content/uploads/2023/03/legogif.gif"
                  data-elementor-open-lightbox="yes"
                  data-elementor-lightbox-title="lego+gif"
                  data-e-action-hash="#elementor-action%3Aaction%3Dlightbox%26settings%3DeyJpZCI6MzE5OSwidXJsIjoiaHR0cHM6XC9cL3RoZWh1bWFudGVrLmNvbVwvd3AtY29udGVudFwvdXBsb2Fkc1wvMjAyM1wvMDNcL2xlZ29naWYuZ2lmIn0%3D"
                >
                  <img
                    src="https://thehumantek.com/wp-content/uploads/2023/03/legogif.gif"
                    title="lego+gif"
                    alt="lego+gif"
                    className="elementor-animation-shrink img-fluid"
                    loading="lazy"
                    style={{ maxWidth: '50%', height: 'auto' }}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="container py-4">
        <div className="row justify-content-center align-items-center">
          {/* Left Column - Image */}
          <div className="col-md-6 animated fadeInLeft pr-md-4">
            <div className="elementor-widget-wrap">
              <div className="elementor-element elementor-element-126a738 elementor-widget elementor-widget-image">
                <div className="elementor-widget-container">
                  <a
                    href="https://thehumantek.com/wp-content/uploads/2023/08/White-board.png"
                    data-elementor-open-lightbox="yes"
                    data-elementor-lightbox-title="White board"
                    data-e-action-hash="#elementor-action%3Aaction%3Dlightbox%26settings%3DeyJpZCI6NzIwNywidXJsIjoiaHR0cHM6XC9cL3RoZWh1bWFudGVrLmNvbVwvd3AtY29udGVudFwvdXBsb2Fkc1wvMjAyM1wvMDhcL1doaXRlLWJvYXJkLnBuZyJ9"
                  >
                    <img
                      src="https://thehumantek.com/wp-content/uploads/2023/08/White-board.png"
                      title="White board"
                      alt="White board"
                      className="elementor-animation-shrink img-fluid"
                      loading="lazy"
                      style={{ maxWidth: '50%', height: 'auto' }}
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>

          {/* Right Column - Text Content */}
          <div className="col-md-6 animated fadeInRight pl-md-4">
            <div className="elementor-widget-wrap">
              <div className="elementor-element elementor-element-101344d elementor-widget elementor-widget-heading">
                <div className="elementor-widget-container">
                  <h2 className="elementor-heading-title elementor-size-default">Engaging Story-Telling</h2>
                </div>
              </div>
              <div className="elementor-element elementor-element-2926fd5 elementor-widget elementor-widget-heading">
                <div className="elementor-widget-container">
                  <h2 className="elementor-heading-title elementor-size-default">White Board<br />Animation</h2>
                </div>
              </div>
              <div className="elementor-element elementor-element-3e811fd elementor-widget__width-auto elementor-widget elementor-widget-text-editor">
                <div className="elementor-widget-container">
                  <p>
                    Our skilled whiteboard animators assist you in getting higher ROI by incorporating outstanding ideas using quality tools and advanced software. Techiocracy offers custom whiteboard animation services and productive and enjoyable strategies for incorporating this video format into your digital marketing strategy.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="container py-4">
        <div className="row justify-content-center align-items-center">
          {/* Left Column - Text Content */}
          <div className="col-md-6 animated fadeInLeft pr-md-4">
            <div className="elementor-widget-wrap">
              <div className="elementor-element elementor-element-8523b2e elementor-widget elementor-widget-heading">
                <div className="elementor-widget-container">
                  <h2 className="elementor-heading-title elementor-size-default">Steady Figures</h2>
                </div>
              </div>
              <div className="elementor-element elementor-element-5135325 elementor-widget elementor-widget-heading">
                <div className="elementor-widget-container">
                  <h2 className="elementor-heading-title elementor-size-default">Stop Motion</h2>
                </div>
              </div>
              <div className="elementor-element elementor-element-e786964 elementor-widget__width-auto elementor-widget elementor-widget-text-editor">
                <div className="elementor-widget-container">
                  <p>Stop motion animation is a technique for creating engaging content with a contemporary twist. It explains concepts and information in a fun way. Techiocracy offers best-in-class and high-quality stop motion animation services.</p>
                </div>
              </div>
            </div>
          </div>

          {/* Right Column - Image */}
          <div className="col-md-6 animated fadeInRight pl-md-4">
            <div className="elementor-widget-wrap">
              <div className="elementor-element elementor-element-fc7e38e elementor-widget elementor-widget-image">
                <div className="elementor-widget-container">
                  <a
                    href="https://thehumantek.com/wp-content/uploads/2023/08/fun-3d-illustration-cartoon-kid-with-rain-gear-scaled.jpg"
                    data-elementor-open-lightbox="yes"
                    data-elementor-lightbox-title="Fun 3D Illustration of a cartoon kid with rain gear"
                    data-e-action-hash="#elementor-action%3Aaction%3Dlightbox%26settings%3DeyJpZCI6Njk5OSwidXJsIjoiaHR0cHM6XC9cL3RoZWh1bWFudGVrLmNvbVwvd3AtY29udGVudFwvdXBsb2Fkc1wvMjAyM1wvMDhcL2Z1bi0zZC1pbGx1c3RyYXRpb24tY2FydG9vbi1raWQtd2l0aC1yYWluLWdlYXItc2NhbGVkLmpwZyJ9"
                  >
                    <img
                      src="https://thehumantek.com/wp-content/uploads/2023/08/fun-3d-illustration-cartoon-kid-with-rain-gear-scaled.jpg"
                      title="Fun 3D Illustration of a cartoon kid with rain gear"
                      alt="Fun 3D Illustration of a cartoon kid with rain gear"
                      className="elementor-animation-shrink img-fluid"
                      loading="lazy"
                      style={{ maxWidth: '50%', height: 'auto' }}
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container py-4">
        <div className="row align-items-center">
          {/* Left Column - Image */}
          <div className="col-lg-6 animated fadeInRight pr-lg-4">
            <div className="elementor-widget-wrap">
              <div className="elementor-element elementor-element-ea7c2c0 elementor-widget elementor-widget-image">
                <div className="elementor-widget-container">
                  <a
                    href="https://thehumantek.com/wp-content/uploads/2023/08/Corporate-Explainer-Videos-1.png"
                    data-elementor-open-lightbox="yes"
                    data-elementor-lightbox-title="Corporate Explainer Videos"
                    data-e-action-hash="#elementor-action%3Aaction%3Dlightbox%26settings%3DeyJpZCI6NzIwOSwidXJsIjoiaHR0cHM6XC9cL3RoZWh1bWFudGVrLmNvbVwvd3AtY29udGVudFwvdXBsb2Fkc1wvMjAyM1wvMDhcL0NvcnBvcmF0ZS1FeHBsYWluZXItVmlkZW9zLTEucG5nIn0%3D"
                  >
                    <img
                      src="https://thehumantek.com/wp-content/uploads/2023/08/Corporate-Explainer-Videos-1.png"
                      className="elementor-animation-shrink img-fluid"
                      alt="Corporate Explainer Videos"
                      loading="lazy"
                      style={{ maxWidth: '50%', height: 'auto' }}
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>

          {/* Right Column - Text Content */}
          <div className="col-lg-6 animated fadeInLeft pl-lg-4">
            <div className="elementor-widget-wrap">
              <div className="elementor-element elementor-element-518054a elementor-widget elementor-widget-heading">
                <div className="elementor-widget-container">
                  <h2 className="elementor-heading-title elementor-size-default">Key Message Focused</h2>
                </div>
              </div>
              <div className="elementor-element elementor-element-5585ff8 elementor-widget elementor-widget-heading">
                <div className="elementor-widget-container">
                  <h2 className="elementor-heading-title elementor-size-default">Corporate Explainer Videos</h2>
                </div>
              </div>
              <div className="elementor-element elementor-element-47329e9 elementor-widget__width-auto elementor-widget elementor-widget-text-editor">
                <div className="elementor-widget-container">
                  <p>With an explainer video, you can create a presentation that gets to the heart of your company’s customers. When you include an explainer video on your website landing page, your visitors are more likely to be there. As a result, you will appear higher in Google search results. We at Techiocracy create the finest animated corporate explainer videos that deliver your message with simplicity and clarity.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container py-4 mb-5">
        <div className="row align-items-center">
          {/* Column 1 - Text Content */}
          <div className="col-lg-6 animated fadeInRight pr-lg-4">
            <div className="elementor-widget-wrap">
              <div className="elementor-element elementor-element-ce25d0b elementor-widget elementor-widget-heading">
                <div className="elementor-widget-container">
                  <h2 className="elementor-heading-title elementor-size-default">Vibrant Statics</h2>
                </div>
              </div>
              <div className="elementor-element elementor-element-c5c2a8d elementor-widget elementor-widget-heading">
                <div className="elementor-widget-container">
                  <h2 className="elementor-heading-title elementor-size-default">Motion Graphics</h2>
                </div>
              </div>
              <div className="elementor-element elementor-element-85f83e3 elementor-widget__width-auto elementor-widget elementor-widget-text-editor">
                <div className="elementor-widget-container">
                  <p>Techiocracy offers outstanding motion graphic animation services to customers across Pakistan. Motion graphics helps businesses to introduce their brands or concepts to target audiences. We make an absolute blend of custom animation and illustration to produce high-quality video content.</p>
                </div>
              </div>
            </div>
          </div>

          {/* Column 2 - Image */}
          <div className="col-lg-6 animated fadeInLeft pl-lg-4">
            <div className="elementor-widget-wrap">
              <div className="elementor-element elementor-element-4e712ef elementor-widget elementor-widget-image">
                <div className="elementor-widget-container">
                  <a
                    href="https://thehumantek.com/wp-content/uploads/2023/08/Motion-Graphics.png"
                    data-elementor-open-lightbox="yes"
                    data-elementor-lightbox-title="Motion Graphics"
                    data-e-action-hash="#elementor-action%3Aaction%3Dlightbox%26settings%3DeyJpZCI6NzIxMCwidXJsIjoiaHR0cHM6XC9cL3RoZWh1bWFudGVrLmNvbVwvd3AtY29udGVudFwvdXBsb2Fkc1wvMjAyM1wvMDhcL01vdGlvbi1HcmFwaGljcy5wbmcifQ%3D%3D"
                  >
                    <img
                      src="https://thehumantek.com/wp-content/uploads/2023/08/Motion-Graphics.png"
                      className="elementor-animation-shrink img-fluid"
                      alt="Motion Graphics"
                      loading="lazy"
                      style={{ maxWidth: '50%', height: 'auto' }}
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    
    </>
  );
}


export default services;
