import React from "react";

function mobileapp() {
  return (
    <>
      <div
        className="container-fluid d-flex justify-content-center align-items-center"
        style={{ backgroundColor: "#007bff", color: "#fff", minHeight: "50vh" }}
      >
        <div className="text-center mt-5">
          <h1 className="mb-4">Mobile App Development</h1>
          <h5>Home | Mobile App Development</h5>
        </div>
      </div>

      <div className="container-fluid bg-white text-dark py-5 font-bootstrap">
        <div className="row justify-content-center">
          <div className="col-md-8 text-center">
            <p className="mb-2">
              In this highly technological era, Techiocracy can help you in mobile
              app development for your company and start-ups. The concept of
              daily activities in human life has changed due to mobile apps. We
              use apps to aid us with practically everything. Global
              connectivity is facilitated through mobile apps.
            </p>
            <p>
              Our professional developers provide mobile app development
              services that meet consumer wants, support corporate identities,
              and encourage business growth and expansion.
            </p>
            <h1 className="py-4">Services</h1>
            {/* <hr className="my-5 hr-under-lead" /> */}
          </div>
        </div>
      </div>


      <div className="container py-4">
        <div className="row align-items-center">
          {/* Left Column */}
          <div className="col-lg-6 animated fadeInLeft">
            <div className="elementor-widget-wrap elementor-element-populated">
              <div className="elementor-element elementor-element-671b505 elementor-widget elementor-widget-image">
                <div className="elementor-widget-container">
                  <img
                    fetchpriority="high"
                    decoding="async"
                    width="300"
                    height="300"
                    src="https://thehumantek.com/wp-content/uploads/2023/02/asset-apk.png"
                    className="attachment-full size-full wp-image-770"
                    alt=""
                    srcSet="https://thehumantek.com/wp-content/uploads/2023/02/asset-apk.png 2404w, https://thehumantek.com/wp-content/uploads/2023/02/asset-apk-300x266.png 300w, https://thehumantek.com/wp-content/uploads/2023/02/asset-apk-1024x909.png 1024w, https://thehumantek.com/wp-content/uploads/2023/02/asset-apk-768x682.png 768w, https://thehumantek.com/wp-content/uploads/2023/02/asset-apk-1536x1363.png 1536w, https://thehumantek.com/wp-content/uploads/2023/02/asset-apk-2048x1818.png 2048w"
                    sizes="(max-width: 2404px) 100vw, 2404px"
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Right Column */}
          <div className="col-lg-6 animated fadeInRight">
            <div className="elementor-widget-wrap elementor-element-populated">
              <div className="elementor-element elementor-element-3e4a588 elementor-widget elementor-widget-heading">
                <div className="elementor-widget-container">
                  <h2 className="elementor-heading-title elementor-size-default">Let's Start Today</h2>
                </div>
              </div>
              <div className="elementor-element elementor-element-dcd455b elementor-widget elementor-widget-heading">
                <div className="elementor-widget-container">
                  <h3 className="elementor-heading-title elementor-size-default">App Launch & Deployment</h3>
                </div>
              </div>
              <div className="elementor-element elementor-element-04658a0 elementor-widget elementor-widget-text-editor">
                <div className="elementor-widget-container">
                  <p>Launching your apps to the app stores is a time-consuming process. After you’ve added them, you’ll need to market them to let others know about the publication. At Techiocracy, we make it a point to create user-friendly and helpful software to achieve the aim.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container py-4">
      <section className="has_eae_slider elementor-section elementor-top-section elementor-element elementor-element-5321c3b elementor-reverse-mobile elementor-section-boxed elementor-section-height-default elementor-section-height-default tf-sticky-section tf-sticky-no" data-id="5321c3b" data-element_type="section">
        <div className="elementor-container elementor-column-gap-default">
          <div className="row align-items-center">
            {/* Left Column */}
            <div className="col-lg-6 animated fadeInLeft">
              <div className="elementor-widget-wrap elementor-element-populated">
                <div className="elementor-element elementor-element-5f2cb5d elementor-widget elementor-widget-heading" data-id="5f2cb5d" data-element_type="widget" data-widget_type="heading.default">
                  <div className="elementor-widget-container">
                    <h3 className="elementor-heading-title elementor-size-default">Mobile App Consulting</h3>
                  </div>
                </div>
                <div className="elementor-element elementor-element-d478441 elementor-widget elementor-widget-text-editor" data-id="d478441" data-element_type="widget" data-widget_type="text-editor.default">
                  <div className="elementor-widget-container">
                    <p>Our mobile app consultancy gives our clients the keys to unlock the winning approach for controlling and disrupting their market and outpacing their competitors. Our team of experts provides comprehensive guidance and support throughout the entire app development process, from ideation to launch, ensuring that our clients’ apps are optimized for success. Additionally, we stay up-to-date with the latest industry trends and technologies to ensure that our clients stay ahead of the curve.</p>
                  </div>
                </div>
              </div>
            </div>

            {/* Right Column */}
            <div className="col-lg-6 animated fadeInRight">
              <div className="elementor-widget-wrap elementor-element-populated">
                <div className="elementor-element elementor-element-b499956 elementor-widget elementor-widget-image animated fadeInRight" data-id="b499956" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeInRight&quot;}" data-widget_type="image.default">
                  <div className="elementor-widget-container">
                    <img
                      decoding="async"
                      width="300"
                      height="300"
                      src="https://thehumantek.com/wp-content/uploads/2023/08/Mobile-App-Consulting.png"
                      className="attachment-full size-full wp-image-7324"
                      alt=""
                      srcSet="https://thehumantek.com/wp-content/uploads/2023/08/Mobile-App-Consulting.png 1080w, https://thehumantek.com/wp-content/uploads/2023/08/Mobile-App-Consulting-300x300.png 300w, https://thehumantek.com/wp-content/uploads/2023/08/Mobile-App-Consulting-1024x1024.png 1024w, https://thehumantek.com/wp-content/uploads/2023/08/Mobile-App-Consulting-150x150.png 150w, https://thehumantek.com/wp-content/uploads/2023/08/Mobile-App-Consulting-768x768.png 768w"
                      sizes="(max-width: 1080px) 100vw, 1080px"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>

    <div className="container py-4">
      <section className="has_eae_slider elementor-section elementor-top-section elementor-element elementor-element-0c953ae elementor-section-boxed elementor-section-height-default elementor-section-height-default tf-sticky-section tf-sticky-no" data-id="0c953ae" data-element_type="section">
        <div className="elementor-container elementor-column-gap-default">
          <div className="row align-items-center">
            {/* Left Column */}
            <div className="col-lg-6 animated fadeInLeft">
              <div className="elementor-widget-wrap elementor-element-populated">
                <div className="elementor-element elementor-element-359429c elementor-widget elementor-widget-image" data-id="359429c" data-element_type="widget" data-widget_type="image.default">
                  <div className="elementor-widget-container">
                    <img
                      loading="lazy"
                      decoding="async"
                      width="300"
                      height="300"
                      src="https://thehumantek.com/wp-content/uploads/2023/08/Mobile-app-uiux.png"
                      className="attachment-full size-full wp-image-7325"
                      alt=""
                      srcSet="https://thehumantek.com/wp-content/uploads/2023/08/Mobile-app-uiux.png 1080w, https://thehumantek.com/wp-content/uploads/2023/08/Mobile-app-uiux-300x300.png 300w, https://thehumantek.com/wp-content/uploads/2023/08/Mobile-app-uiux-1024x1024.png 1024w, https://thehumantek.com/wp-content/uploads/2023/08/Mobile-app-uiux-150x150.png 150w, https://thehumantek.com/wp-content/uploads/2023/08/Mobile-app-uiux-768x768.png 768w"
                      sizes="(max-width: 1080px) 100vw, 1080px"
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* Right Column */}
            <div className="col-lg-6 animated fadeInRight">
              <div className="elementor-widget-wrap elementor-element-populated">
                <div className="elementor-element elementor-element-382f255 elementor-widget elementor-widget-heading" data-id="382f255" data-element_type="widget" data-widget_type="heading.default">
                  <div className="elementor-widget-container">
                    <h3 className="elementor-heading-title elementor-size-default">Mobile UX and UI Design</h3>
                  </div>
                </div>
                <div className="elementor-element elementor-element-1223372 elementor-widget elementor-widget-text-editor" data-id="1223372" data-element_type="widget" data-widget_type="text-editor.default">
                  <div className="elementor-widget-container">
                    <p>Interfaces designed by Techiocracy designers are efficient and simple to use, ensuring conversion, engagement, and simple adoption. Our designers follow the latest trends and best practices in mobile UX and UI design to create visually appealing interfaces that provide a seamless user experience across different devices and platforms. We also conduct user research and testing to ensure that our designs meet the needs and expectations of your target audience.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>

    <div className="container py-4">
      <section className="has_eae_slider elementor-section elementor-top-section elementor-element elementor-element-2339aaf elementor-reverse-mobile elementor-section-boxed elementor-section-height-default elementor-section-height-default tf-sticky-section tf-sticky-no" data-id="2339aaf" data-element_type="section">
        <div className="elementor-container elementor-column-gap-default">
          <div className="row align-items-center">
            {/* Left Column */}
            <div className="col-lg-6 animated fadeInLeft">
              <div className="elementor-widget-wrap elementor-element-populated">
                <div className="elementor-element elementor-element-dd817ed elementor-widget elementor-widget-heading" data-id="dd817ed" data-element_type="widget" data-widget_type="heading.default">
                  <div className="elementor-widget-container">
                    <h3 className="elementor-heading-title elementor-size-default">Mobile App Development</h3>
                  </div>
                </div>
                <div className="elementor-element elementor-element-e789b15 elementor-widget elementor-widget-text-editor" data-id="e789b15" data-element_type="widget" data-widget_type="text-editor.default">
                  <div className="elementor-widget-container">
                    <p>We provide effective mobile app development services to market your project across all platforms and devices. Our team of experienced developers can create custom mobile apps tailored to your specific business needs, ensuring a seamless user experience and optimal performance. Contact us today to discuss your mobile app development requirements.</p>
                    <p>&nbsp;</p>
                  </div>
                </div>
              </div>
            </div>

            {/* Right Column */}
            <div className="col-lg-6 animated fadeInRight">
              <div className="elementor-widget-wrap elementor-element-populated">
                <div className="elementor-element elementor-element-dc2ca74 elementor-widget elementor-widget-image" data-id="dc2ca74" data-element_type="widget" data-widget_type="image.default">
                  <div className="elementor-widget-container">
                    <img
                      loading="lazy"
                      decoding="async"
                      width="300"
                      height="300"
                      src="https://thehumantek.com/wp-content/uploads/2023/08/mobile-app-development.png"
                      className="attachment-full size-full wp-image-7326"
                      alt=""
                      srcSet="https://thehumantek.com/wp-content/uploads/2023/08/mobile-app-development.png 1080w, https://thehumantek.com/wp-content/uploads/2023/08/mobile-app-development-300x300.png 300w, https://thehumantek.com/wp-content/uploads/2023/08/mobile-app-development-1024x1024.png 1024w, https://thehumantek.com/wp-content/uploads/2023/08/mobile-app-development-150x150.png 150w, https://thehumantek.com/wp-content/uploads/2023/08/mobile-app-development-768x768.png 768w"
                      sizes="(max-width: 1080px) 100vw, 1080px"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>

    <div className="container py-4">
      <section className="has_eae_slider elementor-section elementor-top-section elementor-element elementor-element-5d8a06a elementor-section-boxed elementor-section-height-default elementor-section-height-default tf-sticky-section tf-sticky-no" data-id="5d8a06a" data-element_type="section">
        <div className="elementor-container elementor-column-gap-default">
          <div className="row align-items-center">
            {/* Left Column */}
            <div className="col-lg-6 animated fadeInLeft">
              <div className="elementor-widget-wrap elementor-element-populated">
                <div className="elementor-element elementor-element-c74f7a7 elementor-widget elementor-widget-image" data-id="c74f7a7" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeInLeft&quot;}" data-widget_type="image.default">
                  <div className="elementor-widget-container">
                    <img
                      loading="lazy"
                      decoding="async"
                      width="300"
                      height="300"
                      src="https://thehumantek.com/wp-content/uploads/2023/08/back-hand-dovelpers.png"
                      className="attachment-full size-full wp-image-7327"
                      alt=""
                      srcSet="https://thehumantek.com/wp-content/uploads/2023/08/back-hand-dovelpers.png 1080w, https://thehumantek.com/wp-content/uploads/2023/08/back-hand-dovelpers-300x300.png 300w, https://thehumantek.com/wp-content/uploads/2023/08/back-hand-dovelpers-1024x1024.png 1024w, https://thehumantek.com/wp-content/uploads/2023/08/back-hand-dovelpers-150x150.png 150w, https://thehumantek.com/wp-content/uploads/2023/08/back-hand-dovelpers-768x768.png 768w"
                      sizes="(max-width: 1080px) 100vw, 1080px"
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* Right Column */}
            <div className="col-lg-6 animated fadeInRight">
              <div className="elementor-widget-wrap elementor-element-populated">
                <div className="elementor-element elementor-element-749483f elementor-widget elementor-widget-heading" data-id="749483f" data-element_type="widget" data-widget_type="heading.default">
                  <div className="elementor-widget-container">
                    <h3 className="elementor-heading-title elementor-size-default">Back end development</h3>
                  </div>
                </div>
                <div className="elementor-element elementor-element-b380311 elementor-widget elementor-widget-text-editor" data-id="b380311" data-element_type="widget" data-widget_type="text-editor.default">
                  <div className="elementor-widget-container">
                    <p>To guarantee a seamless and speedy data sync and transfer, our back-end developers provide strong and secure mobile back ends. They also ensure that the server side of the application is optimized for performance and scalability, allowing for a smooth user experience even during high-traffic periods.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>

    <div className="container py-4">
      <section className="has_eae_slider elementor-section elementor-top-section elementor-element elementor-element-4b2c3ac elementor-reverse-mobile elementor-section-boxed elementor-section-height-default elementor-section-height-default tf-sticky-section tf-sticky-no" data-id="4b2c3ac" data-element_type="section">
        <div className="elementor-container elementor-column-gap-default">
          <div className="row align-items-center">
            {/* Left Column */}
            <div className="col-lg-6 animated fadeInLeft">
              <div className="elementor-widget-wrap elementor-element-populated">
                <div className="elementor-element elementor-element-818735e elementor-widget elementor-widget-heading" data-id="818735e" data-element_type="widget" data-widget_type="heading.default">
                  <div className="elementor-widget-container">
                    <h3 className="elementor-heading-title elementor-size-default">Web – Development</h3>
                  </div>
                </div>
                <div className="elementor-element elementor-element-8db74c6 elementor-widget elementor-widget-text-editor" data-id="8db74c6" data-element_type="widget" data-widget_type="text-editor.default">
                  <div className="elementor-widget-container">
                    <p>Techiocracy can improve your mobile app with a web app, web portal, online store, or SaaS with transparency. We use cutting-edge technologies and agile methodologies to ensure that your web development project is delivered on time and within budget. Our team of experienced developers can also provide ongoing support and maintenance to ensure the continued success of your web application.</p>
                  </div>
                </div>
              </div>
            </div>

            {/* Right Column */}
            <div className="col-lg-6 animated fadeInRight">
              <div className="elementor-widget-wrap elementor-element-populated">
                <div className="elementor-element elementor-element-40839df elementor-widget elementor-widget-image" data-id="40839df" data-element_type="widget" data-widget_type="image.default">
                  <div className="elementor-widget-container">
                    <img
                      loading="lazy"
                      decoding="async"
                      width="300"
                      height="300"
                      src="https://thehumantek.com/wp-content/uploads/2023/08/Web-–-Development.png"
                      className="attachment-full size-full wp-image-7328"
                      alt=""
                      srcSet="https://thehumantek.com/wp-content/uploads/2023/08/Web-–-Development.png 1080w, https://thehumantek.com/wp-content/uploads/2023/08/Web-–-Development-300x300.png 300w, https://thehumantek.com/wp-content/uploads/2023/08/Web-–-Development-1024x1024.png 1024w, https://thehumantek.com/wp-content/uploads/2023/08/Web-–-Development-150x150.png 150w, https://thehumantek.com/wp-content/uploads/2023/08/Web-–-Development-768x768.png 768w"
                      sizes="(max-width: 1080px) 100vw, 1080px"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>

    <div className="container py-4">
      <section className="has_eae_slider elementor-section elementor-top-section elementor-element elementor-element-ce32133 elementor-section-boxed elementor-section-height-default elementor-section-height-default tf-sticky-section tf-sticky-no" data-id="ce32133" data-element_type="section">
        <div className="elementor-container elementor-column-gap-default">
          <div className="row align-items-center">
            {/* Left Column */}
            <div className="col-lg-6 animated fadeInLeft">
              <div className="elementor-widget-wrap elementor-element-populated">
                <div className="elementor-element elementor-element-43980ee elementor-widget elementor-widget-image" data-id="43980ee" data-element_type="widget" data-widget_type="image.default">
                  <div className="elementor-widget-container">
                    <img
                      loading="lazy"
                      decoding="async"
                      width="300"
                      height="300"
                      src="https://thehumantek.com/wp-content/uploads/2023/08/Mobile-App-Integration.png"
                      className="attachment-full size-full wp-image-7329"
                      alt=""
                      srcSet="https://thehumantek.com/wp-content/uploads/2023/08/Mobile-App-Integration.png 1080w, https://thehumantek.com/wp-content/uploads/2023/08/Mobile-App-Integration-300x300.png 300w, https://thehumantek.com/wp-content/uploads/2023/08/Mobile-App-Integration-1024x1024.png 1024w, https://thehumantek.com/wp-content/uploads/2023/08/Mobile-App-Integration-150x150.png 150w, https://thehumantek.com/wp-content/uploads/2023/08/Mobile-App-Integration-768x768.png 768w"
                      sizes="(max-width: 1080px) 100vw, 1080px"
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* Right Column */}
            <div className="col-lg-6 animated fadeInRight">
              <div className="elementor-widget-wrap elementor-element-populated">
                <div className="elementor-element elementor-element-6a20b61 elementor-widget elementor-widget-heading" data-id="6a20b61" data-element_type="widget" data-widget_type="heading.default">
                  <div className="elementor-widget-container">
                    <h3 className="elementor-heading-title elementor-size-default">Mobile App Integration</h3>
                  </div>
                </div>
                <div className="elementor-element elementor-element-4cf7e80 elementor-widget elementor-widget-text-editor" data-id="4cf7e80" data-element_type="widget" data-widget_type="text-editor.default">
                  <div className="elementor-widget-container">
                    <p>We have successfully combined a number of third-party services with mobile applications as well as with the existing web, mobile, or database systems. This integration has resulted in a more streamlined and efficient workflow, allowing for greater productivity and cost savings. It has also enhanced the user experience by providing a seamless integration of various services into one platform.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>


    <div className="container py-4">
      <section className="has_eae_slider elementor-section elementor-top-section elementor-element elementor-element-0fb9753 elementor-reverse-mobile elementor-section-boxed elementor-section-height-default elementor-section-height-default tf-sticky-section tf-sticky-no" data-id="0fb9753" data-element_type="section">
        <div className="elementor-container elementor-column-gap-default">
          <div className="row align-items-center">
            {/* Left Column */}
            <div className="col-lg-6 animated fadeInLeft">
              <div className="elementor-widget-wrap elementor-element-populated">
                <div className="elementor-element elementor-element-fec5c4f elementor-widget elementor-widget-heading" data-id="fec5c4f" data-element_type="widget" data-widget_type="heading.default">
                  <div className="elementor-widget-container">
                    <h3 className="elementor-heading-title elementor-size-default">QA and Testing</h3>
                  </div>
                </div>
                <div className="elementor-element elementor-element-f74dd30 elementor-widget elementor-widget-text-editor" data-id="f74dd30" data-element_type="widget" data-widget_type="text-editor.default">
                  <div className="elementor-widget-container">
                    <p>Our QA team tests the functionality of mobile apps after integrating them with various demanding systems to ensure flawless data movement from one application to another. They also conduct regression testing to ensure that new features do not negatively impact existing functionalities and provide feedback to the development team for continuous improvement.</p>
                  </div>
                </div>
              </div>
            </div>

            {/* Right Column */}
            <div className="col-lg-6 animated fadeInRight">
              <div className="elementor-widget-wrap elementor-element-populated">
                <div className="elementor-element elementor-element-c625758 elementor-widget elementor-widget-image" data-id="c625758" data-element_type="widget" data-widget_type="image.default">
                  <div className="elementor-widget-container">
                    <img
                      loading="lazy"
                      decoding="async"
                      width="300"
                      height="300"
                      src="https://thehumantek.com/wp-content/uploads/2023/08/QA-and-Testing-1.png"
                      className="attachment-full size-full wp-image-7331"
                      alt=""
                      srcSet="https://thehumantek.com/wp-content/uploads/2023/08/QA-and-Testing-1.png 1080w, https://thehumantek.com/wp-content/uploads/2023/08/QA-and-Testing-1-300x300.png 300w, https://thehumantek.com/wp-content/uploads/2023/08/QA-and-Testing-1-1024x1024.png 1024w, https://thehumantek.com/wp-content/uploads/2023/08/QA-and-Testing-1-150x150.png 150w, https://thehumantek.com/wp-content/uploads/2023/08/QA-and-Testing-1-768x768.png 768w"
                      sizes="(max-width: 1080px) 100vw, 1080px"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>


    <div className="container py-4">
      <section className="has_eae_slider elementor-section elementor-top-section elementor-element elementor-element-9116364 elementor-section-boxed elementor-section-height-default elementor-section-height-default tf-sticky-section tf-sticky-no" data-id="9116364" data-element_type="section">
        <div className="elementor-container elementor-column-gap-default">
          <div className="row align-items-center">
            {/* Left Column */}
            <div className="col-lg-6 animated fadeInLeft">
              <div className="elementor-widget-wrap elementor-element-populated">
                <div className="elementor-element elementor-element-83afe33 elementor-widget elementor-widget-image" data-id="83afe33" data-element_type="widget" data-widget_type="image.default">
                  <div className="elementor-widget-container">
                    <img
                      loading="lazy"
                      decoding="async"
                      width="300" // Set width to 300px
                      height="300" // Set height to 300px
                      src="https://thehumantek.com/wp-content/uploads/2023/08/Maintenance-and-Support-1-1.png"
                      className="attachment-full size-full wp-image-7333"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* Right Column */}
            <div className="col-lg-6 animated fadeInRight">
              <div className="elementor-widget-wrap elementor-element-populated">
                <div className="elementor-element elementor-element-9c1ef52 elementor-widget elementor-widget-heading" data-id="9c1ef52" data-element_type="widget" data-widget_type="heading.default">
                  <div className="elementor-widget-container">
                    <h3 className="elementor-heading-title elementor-size-default">Maintenance and Support</h3>
                  </div>
                </div>
                <div className="elementor-element elementor-element-357ab97 elementor-widget elementor-widget-text-editor" data-id="357ab97" data-element_type="widget" data-widget_type="text-editor.default">
                  <div className="elementor-widget-container">
                    <p>Techiocracy provides performance management, app evolution, compliance, security, and post-launch support. Our team of experts is available 24/7 to ensure that your app runs smoothly and efficiently. We also offer regular maintenance updates to keep your app up-to-date with the latest technology and security standards.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>

    <div className="container py-4">
      <section className="has_eae_slider elementor-section elementor-top-section elementor-element elementor-element-fdf7832 elementor-reverse-mobile elementor-section-boxed elementor-section-height-default elementor-section-height-default tf-sticky-section tf-sticky-no" data-id="fdf7832" data-element_type="section">
        <div className="elementor-container elementor-column-gap-default">
          <div className="row align-items-center">
            {/* Left Column */}
            <div className="col-lg-6 animated fadeInLeft">
              <div className="elementor-widget-wrap elementor-element-populated">
                <div className="elementor-element elementor-element-c291e0d elementor-widget elementor-widget-heading" data-id="c291e0d" data-element_type="widget" data-widget_type="heading.default">
                  <div className="elementor-widget-container">
                    <h3 className="elementor-heading-title elementor-size-default">Mobile App Modernization</h3>
                  </div>
                </div>
                <div className="elementor-element elementor-element-9e8bc41 elementor-widget elementor-widget-text-editor" data-id="9e8bc41" data-element_type="widget" data-widget_type="text-editor.default">
                  <div className="elementor-widget-container">
                    <p>Our experts efficiently transform websites into mobile applications. Our developers expertly rebuild architectures, rearrange code, and create mobile apps from websites by converting websites into mobile applications, we ensure that businesses can reach a wider audience and improve their online presence. Our team uses the latest technology and design techniques to provide seamless user experiences across all devices.</p>
                  </div>
                </div>
              </div>
            </div>

            {/* Right Column */}
            <div className="col-lg-6 animated fadeInRight">
              <div className="elementor-widget-wrap elementor-element-populated">
                <div className="elementor-element elementor-element-b4d911d elementor-widget elementor-widget-image" data-id="b4d911d" data-element_type="widget" data-widget_type="image.default">
                  <div className="elementor-widget-container">
                    <img
                      loading="lazy"
                      decoding="async"
                      width="450" // Set width to 450px
                      height="450" // Set height to 450px
                      src="https://thehumantek.com/wp-content/uploads/2023/02/WB-07.png"
                      className="attachment-full size-full wp-image-781"
                      alt=""
                      srcSet="https://thehumantek.com/wp-content/uploads/2023/02/WB-07.png 450w, https://thehumantek.com/wp-content/uploads/2023/02/WB-07-300x300.png 300w, https://thehumantek.com/wp-content/uploads/2023/02/WB-07-150x150.png 150w"
                      sizes="(max-width: 450px) 100vw, 450px"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    </>
  );
}

export default mobileapp;
