import suvage from "../images/vector.png";
import "./card.css";
import { Link } from "react-router-dom";

function card() {
  return (
    <>
      <div className="anim-bg">
        <div className="container mt-1">
          <h2
            className="heading-2"
            style={{
              fontSize: "60px",
              fontFamily: "900",
              textAlign: "center",
              color: "black",
              paddingTop: "30px",
            }}
          >
            Explore Our Pricing Packages
          </h2>
          <p
            className="paragraph-p"
            style={{
              fontSize: "20px",
              fontFamily: "500",
              textAlign: "center",
              marginTop: "1em",
              color: "#00008B",
            }}
          >
            At Techiocracy, we transform imaginative concepts
            <br /> into sophisticated designs, captivating experiences, and
            memorable brands.
          </p>
          <div className="row">
            {/* First Card */}
            <div className="col-lg-4 col-md-6 mb-4">
              <div className="card text-left" style={{ position: "relative" }}>
                <div
                  className="badge"
                  style={{
                    position: "absolute",
                    top: "20px",
                    right: "0px",
                    backgroundColor: "#f91c1d",
                    color: "white",
                    padding: "10px 13px",
                    borderRadius: "5px",
                    fontSize: "14px",
                    fontWeight: "bold",
                    transform: "rotate(40deg)",
                    whiteSpace: "nowrap",
                  }}
                >
                  70% OFF
                </div>
                <div className="card-header bg-dark text-white d-flex align-items-center justify-content-around">
                  <img
                    src={suvage}
                    alt=""
                    className="img-fluid rounded-circle"
                    style={{
                      maxWidth: "50px",
                      height: "auto",
                      backgroundColor: "lightblue",
                      padding: "5px",
                    }}
                  />
                  <div className="text-left" style={{ height: "100px" }}>
                    <h4
                      style={{
                        color: "white",
                        marginBottom: "10px",
                        paddingLeft: "5px",
                      }}
                    >
                      Starter
                    </h4>
                    <p
                      style={{
                        color: "white",
                        marginBottom: "10px",
                        paddingLeft: "5px",
                      }}
                    >
                      Best Value for Money Guaranteed!
                    </p>
                  </div>
                </div>
                <div className="card-body p-0">
                  <div
                    className="d-flex justify-content-center"
                    style={{ backgroundColor: "#F5DEB3" }}
                  >
                    <h2>$199</h2>
                    <p>
                      <strike>$298</strike>
                      <br />
                      only
                    </p>
                  </div>
                  <div
                    style={{
                      maxHeight: "250px",
                      overflowY: "auto",
                      paddingRight: "10px",
                    }}
                  >
                    <ul
                      className="list-styled text-left"
                      style={{ marginRight: "-10px" }}
                    >
                      <li>3 Page Website</li>
                      <li>3 Banner Design</li>
                      <li>5 Stock Photos</li>
                      <li>1 jQuery Slider Banner</li>
                      <li>FREE Google Friendly Sitemap</li>
                      <li>Complete W3C Certified HTML</li>
                      <li>48 to 72 hours TAT (Turnaround Time)</li>
                      <li>Facebook Page Design</li>
                      <li>Twitter Page Design</li>
                      <li>YouTube Page Design</li>
                      <li>100% Satisfaction Guarantee</li>
                      <li>100% Unique Design Guarantee</li>
                      <li>100% Money Back Guarantee *</li>
                    </ul>
                  </div>
                  <div className="d-flex justify-content-center mt-3">
                    <Link
                      to="mailto:osama@techiocracy.com"
                      className="btn btn-bg-cerd text-white">
                      START PROJECT
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            {/* Second Card */}
            <div className="col-lg-4 col-md-6 mb-4">
              <div className="card text-left" style={{ position: "relative" }}>
                <div
                  className="badge"
                  style={{
                    position: "absolute",
                    top: "20px",
                    right: "0px",
                    backgroundColor: "#f91c1d",
                    color: "white",
                    padding: "10px 13px",
                    borderRadius: "5px",
                    fontSize: "14px",
                    fontWeight: "bold",
                    transform: "rotate(40deg)",
                    whiteSpace: "nowrap",
                  }}
                >
                  70% OFF
                </div>
                <div className="card-header bg-dark text-white d-flex align-items-center justify-content-around">
                  <img
                    src={suvage}
                    alt=""
                    className="img-fluid rounded-circle"
                    style={{
                      maxWidth: "50px",
                      height: "auto",
                      backgroundColor: "lightblue",
                      padding: "5px",
                    }}
                  />
                  <div className="text-left" style={{ height: "100px" }}>
                    <h4
                      style={{
                        color: "white",
                        marginBottom: "10px",
                        paddingLeft: "5px",
                      }}
                    >
                      Professional
                    </h4>
                    <p
                      style={{
                        color: "white",
                        marginBottom: "10px",
                        paddingLeft: "5px",
                      }}
                    >
                      Best Value for Money Guaranteed!
                    </p>
                  </div>
                </div>
                <div className="card-body p-0">
                  <div
                    className="d-flex justify-content-center"
                    style={{ backgroundColor: "#E0FFFF" }}
                  >
                    <h2>$349</h2>
                    <p>
                      <strike>$789</strike>
                      <br />
                      only
                    </p>
                  </div>
                  <div
                    style={{
                      maxHeight: "250px",
                      overflowY: "auto",
                      paddingRight: "10px",
                    }}
                  >
                    <ul
                      className="list-styled text-left"
                      style={{ marginRight: "-10px" }}
                    >
                      <li>5 Unique Pages Website</li>
                      <li>CMS / Admin Panel Support</li>
                      <li>8 Stock images</li>
                      <li>5 Banner Designs</li>
                      <li>1 jQuery Slider Banner</li>
                      <li>FREE Google Friendly Sitemap</li>
                      <li>Complete W3C Certified HTML</li>
                      <li>Mobile Responsive Website</li>
                      <li>48 to 72 hours TAT (Turnaround Time)</li>
                      <li>Facebook Page Design</li>
                      <li>Twitter Page Design</li>
                      <li>YouTube Page Design</li>
                      <li>Complete Deployment</li>
                      <li>100% Satisfaction Guarantee</li>
                      <li>100% Unique Design Guarantee</li>
                      <li>100% Money Back Guarantee *</li>
                    </ul>
                  </div>
                  <div className="d-flex justify-content-center mt-3">
                    <Link
                      to="mailto:osama@techiocracy.com"
                      className="btn btn-bg-cerd text-white"
                    >
                      START PROJECT
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            {/* Third Card */}
            <div className="col-lg-4 col-md-6 mb-4">
              <div className="card text-left" style={{ position: "relative" }}>
                <div
                  className="badge"
                  style={{
                    position: "absolute",
                    top: "20px",
                    right: "0px",
                    backgroundColor: "#f91c1d",
                    color: "white",
                    padding: "10px 13px",
                    borderRadius: "5px",
                    fontSize: "14px",
                    fontWeight: "bold",
                    transform: "rotate(40deg)",
                    whiteSpace: "nowrap",
                  }}
                >
                  70% OFF
                </div>
                <div className="card-header bg-dark text-white d-flex align-items-center justify-content-around">
                  <img
                    src={suvage}
                    alt=""
                    className="img-fluid rounded-circle"
                    style={{
                      maxWidth: "50px",
                      height: "auto",
                      backgroundColor: "lightblue",
                      padding: "5px",
                    }}
                  />
                  <div className="text-left" style={{ height: "100px" }}>
                    <h4
                      style={{
                        color: "white",
                        marginBottom: "0px",
                        paddingLeft: "5px",
                      }}
                    >
                      Business Website Package
                    </h4>
                    <p
                      style={{
                        color: "white",
                        marginBottom: "10px",
                        paddingLeft: "5px",
                      }}
                    >
                      Best Value for Money Guaranteed!
                    </p>
                  </div>
                </div>
                <div className="card-body p-0">
                  <div
                    className="d-flex justify-content-center"
                    style={{ backgroundColor: "#E6E6FA" }}
                  >
                    <h2>$449</h2>
                    <p>
                      <strike>$1698</strike>
                      <br />
                      only
                    </p>
                  </div>
                  <div
                    style={{
                      maxHeight: "250px",
                      overflowY: "auto",
                      paddingRight: "10px",
                    }}
                  >
                    <ul
                      className="list-styled text-left"
                      style={{ marginRight: "-10px" }}
                    >
                      <li>8-10 Page Website</li>
                      <li>Conceptual and Dynamic Website</li>
                      <li>Online Payment Integration</li>
                      <li>Online Booking Tool</li>
                      <li>Content Management System</li>
                      <li>Mobile Responsive Website</li>
                      <li>Custom Forms</li>
                      <li>Lead Capturing Forms</li>
                      <li>Striking Hover Effects</li>
                      <li>Newsletter Subscription</li>
                      <li>Newsfeed Integration</li>
                      <li>Social Media Integration</li>
                      <li>Search Engine Submission</li>
                      <li>5 Stock Photos</li>
                      <li>3 Unique Banner Design</li>
                      <li>1 jQuery Slider Banner</li>
                      <li>Complete W3C Certified HTML</li>
                      <li>48 to 72 hours TAT (Turnaround Time)</li>
                      <li>Facebook Page Design</li>
                      <li>Twitter Page Design</li>
                      <li>YouTube Page Design</li>
                      <li>Complete Deployment</li>
                      <li>100% Unique Design Guarantee</li>
                      <li>100% Money Back Guarantee *</li>
                    </ul>
                  </div>
                  <div className="d-flex justify-content-center mt-3">
                    <Link
                      to="mailto:osama@techiocracy.com"
                      className="btn btn-bg-cerd text-white"
                    >
                      START PROJECT
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            {/* Fourth Card */}
            <div className="col-lg-4 col-md-6 mb-4">
              <div className="card text-left" style={{ position: "relative" }}>
                <div
                  className="badge"
                  style={{
                    position: "absolute",
                    top: "20px",
                    right: "0px",
                    backgroundColor: "#f91c1d",
                    color: "white",
                    padding: "10px 13px",
                    borderRadius: "5px",
                    fontSize: "14px",
                    fontWeight: "bold",
                    transform: "rotate(40deg)",
                    whiteSpace: "nowrap",
                  }}
                >
                  70% OFF
                </div>
                <div className="card-header bg-dark text-white d-flex align-items-center justify-content-around">
                  <img
                    src={suvage}
                    alt=""
                    className="img-fluid rounded-circle"
                    style={{
                      maxWidth: "50px",
                      height: "auto",
                      backgroundColor: "lightblue",
                      padding: "5px",
                    }}
                  />
                  <div className="text-left" style={{ height: "100px" }}>
                    <h4
                      style={{
                        color: "white",
                        marginBottom: "0px",
                        paddingLeft: "5px",
                      }}
                    >
                      Startup Ecommerce <br /> Website Package
                    </h4>
                    <p
                      style={{
                        color: "white",
                        marginBottom: "10px",
                        paddingLeft: "5px",
                      }}
                    >
                      Best Value for Money Guaranteed!
                    </p>
                  </div>
                </div>
                <div className="card-body p-0">
                  <div
                    className="d-flex justify-content-center"
                    style={{ backgroundColor: "#98FB98" }}
                  >
                    <h2>$699</h2>
                    <p>
                      <strike>$1599</strike>
                      <br />
                      only
                    </p>
                  </div>
                  <div
                    style={{
                      maxHeight: "250px",
                      overflowY: "auto",
                      paddingRight: "10px",
                    }}
                  >
                    <ul
                      className="list-styled text-left"
                      style={{ marginRight: "-10px" }}
                    >
                      <li>Up to 15 Unique Pages Website</li>
                      <li>Conceptual and Dynamic Website</li>
                      <li>Content Management System (CMS)</li>
                      <li>Mobile Responsive</li>
                      <li>Easy Product Search</li>
                      <li>Product Reviews</li>
                      <li>Up To 100 Products</li>
                      <li>Unlimited Categories</li>
                      <li>Shopping Cart Integration</li>
                      <li>Payment Integration</li>
                      <li>Sales & Inventory Management</li>
                      <li>JQuery Slider</li>
                      <li>Free Google Friendly Sitemap</li>
                      <li>Custom Email Addresses</li>
                      <li>Complete W3C Certified HTML</li>
                      <li>Social Media Designs</li>
                      <li>Complete Deployment</li>
                      <li>Dedicated Accounts Manager</li>
                      <li>100% Ownership Rights</li>
                      <li>100% Satisfaction Guarantee</li>
                      <li>100% Unique Design Guarantee</li>
                      <li>100% Money Back Guarantee</li>
                    </ul>
                  </div>
                  <div className="d-flex justify-content-center mt-3">
                    <Link
                      to="mailto:osama@techiocracy.com"
                      className="btn btn-bg-cerd text-white"
                    >
                      START PROJECT
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            {/* Fifth Card */}
            <div className="col-lg-4 col-md-6 mb-4">
              <div className="card text-left" style={{ position: "relative" }}>
                <div
                  className="badge"
                  style={{
                    position: "absolute",
                    top: "20px",
                    right: "0px",
                    backgroundColor: "#f91c1d",
                    color: "white",
                    padding: "10px 13px",
                    borderRadius: "5px",
                    fontSize: "14px",
                    fontWeight: "bold",
                    transform: "rotate(40deg)",
                    whiteSpace: "nowrap",
                  }}
                >
                  70% OFF
                </div>
                <div className="card-header bg-dark text-white d-flex align-items-center justify-content-around">
                  <img
                    src={suvage}
                    alt=""
                    className="img-fluid rounded-circle"
                    style={{
                      maxWidth: "50px",
                      height: "auto",
                      backgroundColor: "lightblue",
                      padding: "5px",
                    }}
                  />
                  <div className="text-left" style={{ height: "100px" }}>
                    <h4
                      style={{
                        color: "white",
                        marginBottom: "px",
                        paddingLeft: "5px",
                      }}
                    >
                      Business Ecommerce <br /> Website Package
                    </h4>
                    <p
                      style={{
                        color: "white",
                        marginBottom: "10px",
                        paddingLeft: "5px",
                      }}
                    >
                      Best Value for Money Guaranteed!
                    </p>
                  </div>
                </div>
                <div className="card-body p-0">
                  <div
                    className="d-flex justify-content-center"
                    style={{ backgroundColor: "#ADD8E6" }}
                  >
                    <h2>$899</h2>
                    <p>
                      <strike>$2699</strike>
                      <br />
                      only
                    </p>
                  </div>
                  <div
                    style={{
                      maxHeight: "250px",
                      overflowY: "auto",
                      paddingRight: "10px",
                    }}
                  >
                    <ul
                      className="list-styled text-left"
                      style={{ marginRight: "-10px" }}
                    >
                      <li>Unlimited Pages</li>
                      <li>Unlimited Categories</li>
                      <li>Unlimited Revisions</li>
                      <li>Online Payment Integration</li>
                      <li>Full-cycle Shopping Cart And Checkout</li>
                      <li>Sales And Inventory Management</li>
                      <li>Product Reviews</li>
                      <li>Product Support Forum</li>
                      <li>jQuery Slider Banner</li>
                      <li>Up to 10 Custom Made Banner Designs</li>
                      <li>20 Stock Images</li>
                      <li>Special Hover Effects</li>
                      <li>Content Management System (CMS)</li>
                      <li>Multi-lingual</li>
                      <li>Custom Dynamic Forms</li>
                      <li>Signup Area (For Newsletters, Offers etc.)</li>
                      <li>Search Bar</li>
                      <li>
                        Live Feeds of Social Networks integration (Optional)
                      </li>
                      <li>Mobile Responsive Website</li>
                      <li>Free Google Friendly Sitemap</li>
                      <li>Search Engine Submission</li>
                      <li>Complete W3C Certified HTML</li>
                      <li>
                        Industry Specified Team of Expert Designers and
                        Developers
                      </li>
                      <li>Complete Deployment</li>
                      <li>Dedicated Accounts Manager</li>
                      <li>Facebook Page Design</li>
                      <li>Twitter Page Design</li>
                      <li>YouTube Page Design</li>
                      <li>100% Ownership Rights</li>
                      <li>100% Satisfaction Guarantee</li>
                      <li>100% Unique Design Guarantee</li>
                      <li>100% Money Back Guarantee *</li>
                    </ul>
                  </div>
                  <div className="d-flex justify-content-center mt-3">
                    <Link
                      to="mailto:osama@techiocracy.com"
                      className="btn btn-bg-cerd text-white"
                    >
                      START PROJECT
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            {/* Sixth Card */}
            <div className="col-lg-4 col-md-6 mb-4">
              <div className="card text-left" style={{ position: "relative" }}>
                <div
                  className="badge"
                  style={{
                    position: "absolute",
                    top: "20px",
                    right: "0px",
                    backgroundColor: "#f91c1d",
                    color: "white",
                    padding: "10px 13px",
                    borderRadius: "5px",
                    fontSize: "14px",
                    fontWeight: "bold",
                    transform: "rotate(40deg)",
                    whiteSpace: "nowrap",
                  }}
                >
                  70% OFF
                </div>
                <div className="card-header bg-dark text-white d-flex align-items-center justify-content-around">
                  <img
                    src={suvage}
                    alt=""
                    className="img-fluid rounded-circle"
                    style={{
                      maxWidth: "50px",
                      height: "auto",
                      backgroundColor: "lightblue",
                      padding: "5px",
                    }}
                  />
                  <div className="text-left" style={{ height: "100px" }}>
                    <h4
                      style={{
                        color: "white",
                        marginBottom: "0px",
                        paddingLeft: "5px",
                      }}
                    >
                      Enterprise Website <br /> Package
                    </h4>
                    <p
                      style={{
                        color: "white",
                        marginBottom: "10px",
                        paddingLeft: "5px",
                      }}
                    >
                      Best Value for Money Guaranteed!
                    </p>
                  </div>
                </div>
                <div className="card-body p-0">
                  <div
                    className="d-flex justify-content-center"
                    style={{ backgroundColor: "#D3D3D3" }}
                  >
                    <h2>$1099</h2>
                    <p>
                      <strike>$4499</strike>
                      <br />
                      only
                    </p>
                  </div>
                  <div
                    style={{
                      maxHeight: "250px",
                      overflowY: "auto",
                      paddingRight: "10px",
                    }}
                  >
                    <ul
                      className="list-styled text-left"
                      style={{ marginRight: "-10px" }}
                    >
                      <li>Unlimited Pages Website</li>
                      <li>
                        Custom Made, Interactive, Dynamic & High End Design
                      </li>
                      <li>Custom WP (or) Custom PHP Development</li>
                      <li>1 jQuery Slider Banner</li>
                      <li>Up to 10 Custom Made Banner Designs</li>
                      <li>10 Stock Images</li>
                      <li>Unlimited Revisions</li>
                      <li>Special Hoover Effects</li>
                      <li>Content Management System (CMS)</li>
                      <li>
                        Online Appointment/Scheduling/Online Ordering
                        Integration (Optional)
                      </li>
                      <li>Online Payment Integration (Optional)</li>
                      <li>Multi Lingual (Optional)</li>
                      <li>Custom Dynamic Forms (Optional)</li>
                      <li>Signup Area (For Newsletters, Offers etc.)</li>
                      <li>Search Bar</li>
                      <li>
                        Live Feeds of Social Networks integration (Optional)
                      </li>
                      <li>Mobile Responsive</li>
                      <li>Free Google Friendly Sitemap</li>
                      <li>Search Engine Submission</li>
                      <li>Complete W3C Certified HTML</li>
                      <li>
                        Industry Specified Team of Expert Designers and
                        Developers
                      </li>
                      <li>Complete Deployment</li>
                      <li>Dedicated Accounts Manager</li>
                      <li>Facebook Page Design</li>
                      <li>Twitter Page Design</li>
                      <li>YouTube Page Design</li>
                      <li>100% Ownership Rights</li>
                      <li>100% Satisfaction Guarantee</li>
                      <li>100% Unique Design Guarantee</li>
                      <li>100% Money Back Guarantee *</li>
                    </ul>
                  </div>
                  <div className="d-flex justify-content-center mt-3">
                    <Link
                      to="mailto:osama@techiocracy.com"
                      className="btn btn-bg-cerd text-white"
                    >
                      START PROJECT
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default card;
