import React, { useState, useEffect, useRef } from "react";
import { Navbar, Nav, Container, NavDropdown } from "react-bootstrap";
import logo from '../images/final-logo.svg';
import { Link } from "react-router-dom";
import './header.css'
import { IoCallSharp } from "react-icons/io5";

function Header() {
  const [showNavbar, setShowNavbar] = useState(true);
  const prevScrollPos = useRef(window.pageYOffset);

  const handleScroll = () => {
    const currentScrollPos = window.pageYOffset;
    const visible = prevScrollPos.current > currentScrollPos || currentScrollPos < 10;

    setShowNavbar(visible);
    prevScrollPos.current = currentScrollPos;
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Navbar expand="lg" bg="black" variant="dark" className={showNavbar ? 'navbar-visible' : 'navbar-hidden'}>
      <Container>
        <Navbar.Brand as={Link} to="/" style={{ marginBottom: '-.4em', marginTop: '-0.4em' }}>
          <img src={logo} alt="Company Logo" style={{ width: '200px' }} />
        </Navbar.Brand>

         

        <Navbar.Toggle aria-controls="navbarSupportedContent" />
        <Navbar.Collapse id="navbarSupportedContent" className="justify-content-end">
        <ul className="navbar-phone-list">
          <Nav.Link as={Link} to="tel:+12063171388">
            <li>
          <IoCallSharp/>
              +1 2063171388</li>
              </Nav.Link>
          </ul>
          <Nav>
            <Nav.Link as={Link} to="/" className="mx-3">Home</Nav.Link>
            <Nav.Link as={Link} to="/our-company" className="mx-3">Our Company</Nav.Link>
            <NavDropdown title="Services" id="services-dropdown" className="mx-3">
              <NavDropdown.Item as={Link} to="/software-development">Software Development</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item as={Link} to="/web-development">Web Development</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item as={Link} to="/mobile-app-development">Mobile App Development</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item as={Link} to="/animation">2D & 3D Animation</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item as={Link} to="/graphic-designing">Graphic Designing</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item as={Link} to="/digital-marketing">Digital Marketing</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item as={Link} to="/seo">Search Engine Optimization</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item as={Link} to="/ai">Artificial Intelligence</NavDropdown.Item>
            </NavDropdown>
            <Nav.Link as={Link} to="/portfolio" className="mx-3">Our Portfolio</Nav.Link>
            <Nav.Link as={Link} to="/packages" className="mx-3">Packages</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
