import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './toolslider.css';
import javascriptIcon from '../images/javascript-1.svg';
import android from '../images/android-6.svg';
import angular from '../images/angular-icon.svg';
import nodejs from '../images/nodejs-1.svg';
import apple from '../images/apple-11.svg';
import react from '../images/react-2.svg';
import flutter from '../images/flutter.svg';
import python from '../images/python-3.svg';


const tools = [
  { img: require('../images/shopify.png') },
  { img: flutter },
  { img: require('../images/laravel.png') },
  { img: require('../images/html.png') },
  { img: javascriptIcon },
  { img: require('../images/megento.png') },
  { img: require('../images/wordpress.png') },
  { img: android },
  { img: require('../images/wocommerce.png') },
  { img: require('../images/opencart.png') },
  { img: nodejs },
  { img: angular },
  { img: apple },
  { img: react },
  { img: python },
  // Add more tools as needed
];

const ToolSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <div className="slider-container">
        <h6>We Work Across All Platforms</h6>
      <Slider {...settings}>
        {tools.map((tool, index) => (
          <div key={index} className="slide">
            <img src={tool.img} alt={tool.name} />
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default ToolSlider;
